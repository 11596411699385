import React, { useState, useEffect } from 'react';

// * IMPORTS REACT FILEPOND
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const UploadFiles = ({ process, panel, file }) => {


    useEffect(() => {
        console.log(`${process} - ${panel} - ${file}`);
    }, [process, panel, file])

    // * HOOKS
    // Filepond
    const [files, setFiles] = useState([])

    return (
        <div>
            <FilePond
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={true}
                allowReorder={true}
                maxFiles={3}
                name="files"
                labelIdle='Arrastra y suelta tus archivos o <span class="filepond--label-action">Navegar</span>'
            />
        </div>
    )
}
