import React, { useEffect, useState } from 'react';

import { Global } from '../../../../../helpers/Global';
import { useAlert } from '../../../../../hooks/useAlert';

import { Grid, Container, Dialog, AppBar, Toolbar, IconButton, Typography, Slide, Snackbar, Tooltip, Backdrop, CircularProgress } from '@mui/material';

import MUIDataTable from "mui-datatables";

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es-mx';

import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const AssignOnePawnAdjustmentSlips = ({ open, onClose, idProcessPawn }) => {

	const [pawnAdjustmentSlips, setPawnAdjustmentSlips] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	// Alerts Message
	const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();

	useEffect(() => {
		getPawnAdjustmentSlips().then(result => {
			setPawnAdjustmentSlips(result.data);
		});
	}, []);

	const getPawnAdjustmentSlips = async () => {
		// Conseguir el token de usuario
		const token = localStorage.getItem("token");

		let request = await fetch(`${Global.url_api}api/auth/get-pawn-adjustment-slips`, {
			method: 'GET',
			headers: {
				"Authorization": `Bearer  ${token}`,
				"Content-Type": "application/json"
			},
			redirect: 'follow'
		});

		let response = await request.json();
		return response;
	}

	const columns = [
		{
			name: "id",
			label: "ID"
		},
		{
			name: "vin",
			label: "VIN"
		},
		{
			name: "name",
			label: "Nombre del Cliente"
		},
		{
			name: "created_at",
			options: {
				customBodyRender: (value) => {
					return (
						<Moment locale="es-mx" format="D MMM YYYY" withTitle>
							{value}
						</Moment>
					);
				}
			},
			label: "Fecha"
		},
		{
			name: "id",
			options: {
				customBodyRender: (value) => {
					return (
						<>
							<Grid container spacing={2}>
								<Grid item xs={2}>
									<Tooltip title="Asignar">
										<IconButton style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} color='success' aria-label="assignPawnAdjustmentSlip" size="small" onClick={() => assignPawnAdjustmentSlip(value)}>
											<AddCircleOutlineOutlinedIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</>
					);
				}
			},
			label: "Acciones"
		}
	];

	const options = {
		selectableRowsHideCheckboxes: true,
		filterType: 'textField',
		textLabels: {
			body: {
				noMatch: "Lo sentimos, no se encontraron registros coincidentes.",
				toolTip: "Sort",
				columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			pagination: {
				next: "Siguiente pagina",
				previous: "Pagina anterior",
				rowsPerPage: "Filas por pagina:",
				displayRows: "de",
			},
			toolbar: {
				search: "Buscar",
				downloadCsv: "Descargar CSV",
				print: "Imprimir",
				viewColumns: "Ver columnas",
				filterTable: "Tabla de filtros",
			}
		}
	};

	const assignPawnAdjustmentSlip = async (value) => {

		// Validar que el usuario ya tenga iniciado un proceso de empeño
		if (idProcessPawn === null || idProcessPawn === undefined) {
			showStatusMessage('Este usuario aún no tiene iniciando un proceso de empeño.', 'info');
			return false;
		}

		setIsLoading(true);

		// Conseguir el token de usuario
		const token = localStorage.getItem("token");

		try {

			let request = await fetch(`${Global.url_api}api/auth/assign-pawn-adjustment-slip-client`, {
				method: 'POST',
				headers: {
					"Authorization": `Bearer  ${token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ idProcessPawn: idProcessPawn, idPawnAdjustmentSlip: value }),
				redirect: 'follow'
			});

			let { status, message, code } = await request.json();

			if (code === 201 && status === "success") {
				showStatusMessage('¡Éxito al asociar Boleta de Ajuste de Empeño al Proceso de Empeño!', 'success');
			} else {
				showStatusMessage(`Error inesperado`, 'warning');
				console.log(JSON.stringify(message));
			}

		} catch (error) {
			console.log(error);
			showStatusMessage(`error client: ${error}`, 'error');
		}
		setIsLoading(false);
	}

	return (
		<Dialog
			fullScreen
			open={open}
			onClose={onClose}
			TransitionComponent={Transition}
		>
			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
					{messageSnackbar}
				</Alert>
			</Snackbar>

			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<AppBar sx={{ position: 'relative', bgcolor: '#3340e3' }}>
				<Toolbar>
					<IconButton
						edge="start"
						color="inherit"
						onClick={onClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
					<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
						Asignar Boleta de Ajuste de Empeño
					</Typography>
				</Toolbar>
			</AppBar>
			<Container maxWidth="lg" sx={{ marginTop: 5 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<MUIDataTable
							title={"Boletas de Ajuste de Empeño"}
							data={pawnAdjustmentSlips}
							columns={columns}
							options={options}
						/>
					</Grid>
				</Grid>
			</Container>
		</Dialog>
	)
}
