import React, { useState } from 'react';
import Resizer from 'react-image-file-resizer';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Container, Typography } from '@mui/material';
import { GenerateDocx } from './utils/GenerateDocx';


const Test = () => {

  const data = [
    { name: 'Enero', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Febrero', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Marzo', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Abril', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'Mayo', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Junio', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Julio', uv: 3490, pv: 14300, amt: 12100 },
  ];

  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const newImages = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        Resizer.imageFileResizer(
          file,
          800, // nuevo ancho de la imagen
          800, // nueva altura de la imagen
          'JPEG', // formato de salida (puedes cambiarlo según tus necesidades)
          100, // calidad de la imagen de salida (1-100)
          0, // rotación de la imagen (0, 90, 180, 270)
          (uri) => {
            newImages.push(uri);

            // Si hemos procesado todas las imágenes, actualizamos el estado
            if (newImages.length === files.length) {
              setSelectedImages(newImages);
            }
          },
          'base64' // tipo de salida ('base64' o 'blob')
        );
      }
    }
  };

  return (
    <>
      <input type="file" onChange={handleImageChange} multiple />
      {selectedImages.map((image, index) => (
        <img key={index} src={image} alt={`Resized ${index}`} />
      ))}

      <Container>
        <Typography variant="h5" align="center" gutterBottom>
          Gráfico de Línea
        </Typography>
        <LineChart width={600} height={300} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="pv" stroke="#8884d8" />
          <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
          <Line type="monotone" dataKey="amt" stroke="pink" />
        </LineChart>
      </Container>

      <GenerateDocx data={{
        name: 'hector',
        lastname: 'ponce',
        age: '26',
        job: 'software developer'
      }} template={1} />

    </>
  );
};

export default Test;
