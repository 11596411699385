import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';

// * IMPORTS REACT ROUTER
import { Link } from 'react-router-dom';

// * IMPORTS HELPERS
import { Global } from '../../helpers/Global';

// * IMPORTS DROPZONE - DRAG & DROP
import { MuiFileInput } from 'mui-file-input'

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// * IMPORTS MATERIAL UI - ICONS
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// * IMPORTS MATERIAl UI - SPEED DIAL
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

// * IMPORT MATERIAL UL - MODAL/DIALOG
import Modal from '@mui/material/Modal';

// * IMPORTS MATERIAL UL - PROGRESS SPINNER
import CircularProgress from '@mui/material/CircularProgress';

// * IMPORT MATERIAL UI - ALERT TOAST
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// * IMPORT MATERIAL - BUTTONS AND ICON
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Stack from '@mui/material/Stack';


// * IMPORTS FORM
import Typography from '@mui/material/Typography';

// * MATERIAL UI - CUSTOM STYLES
import { styled } from '@mui/material/styles';



// * IMPORTS MEDIA CUSTOM
// import 	img_ine from '../../assets/img/onboarding/campos-ine.png';

// * Config CSS Modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const DocumentIdOcr2 = () => {

    // * HOOKS
    // Conseguir los datos de usuario y onboarding
    const { auth, onboarding } = useAuth();
    // MUI file input
    const [fileOne, setFileOne] = useState(null);
    const [fileTwo, setFileTwo] = useState(null)
    // INE Ocr
    const [validateIdOcr, setValidateIdOcr] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [saveDocuments, setSaveDocuments] = useState([]);
    // Loading
    const [loading, setLoading] = useState(false);
    const [confirmValidation, setConfirmValidation] = useState(false);
    // Modal
    const [open, setOpen] = useState(false);
    // Speeddeal
    const [direction] = useState('left');
    // Snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState("");
    const [severitySnackbar, setSeveritySnackbar] = useState("");

    // Se ejecutara el Hook en cada ocacion que se actualice el estado de documents
    useEffect(() => {

        // Verifica si se agregaron mas de dos archivos y mitigarlo
        if (documents.length >= 3) {
            setDocuments([]);
            setSaveDocuments([]);
            setConfirmValidation(false);
        }

        console.log(documents);
        if (documents.length === 2) {
            setConfirmValidation(true);
        }
    }, [documents])

    
    // * FUNCTIONS INE OCR
    const processFilesInBase64 = (files, selectFile) => {

        // Reiniciar los valores de todos los hooks
        if (files === null) {
            setFileOne(files);
            setFileTwo(files);
            setDocuments([]);
            setSaveDocuments([]);
            setValidateIdOcr(false);
            setConfirmValidation(false);
            return false;
        }

        // Asignar propiedad de los MUI File input
        if (selectFile === 1) {
            setFileOne(files);
        }
        else {
            setFileTwo(files);
        }



        // Guardamos la configuracion de las dos imaganes para guardarlas por si pasan la verificacion
        setSaveDocuments([...saveDocuments, files]);

        // Arreglo con el base64 de las dos imagenes
        const renderImgs = [];

        // Codificar a Base64 la imagen de INE Frente
        let renderImg1 = new FileReader();
        renderImg1.readAsDataURL(files);
        renderImg1.onload = async () => {
            let base64_split = [];
            let base64 = renderImg1.result;
            base64_split = base64.split(',');
            renderImgs.push(base64_split[1]);
        }

        // Codificar a Base64 la imagen de INE Reverso
        // let renderImg2 = new FileReader();
        // renderImg2.readAsDataURL(files[1].file);
        // renderImg2.onload = () => {
        //     let base64_split = [];
        //     let base64 = renderImg2.result;
        //     base64_split = base64.split(',');
        //     renderImgs.push(base64_split[1]);
        // }

        // Esperamos 2s a que se almacenen los base64 en el estado
        setTimeout(function () {
            setDocuments([...documents, renderImgs]);
        }, 2000);

    };


    const validateIdOcrByNubarium = async () => {

        setLoading(true);

        let raw = JSON.stringify({
            id: documents[0][0],
            idReverso: documents[1][0]
        });

        // Preparar peticion a API
        let requestOptions = {
            method: 'POST',
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            body: raw
        };

        let request = await fetch(`${Global.url_api}api/validate-ine-ocr`, requestOptions);
        let response = await request.json();

        let result = JSON.parse(response);


        if (!result.validacionMRZ && result.estatus !== 'ERROR') {
            // Setear estado porque NO se valido el INE Ocr
            setValidateIdOcr(false);
            // Mostrar mensaje de consulta fallida
            setOpenSnackbar(true);
            setMessageSnackbar('No se logro validar correctamente alguno de los documentos');
            setSeveritySnackbar('info');
        }

        if (result.estatus === 'ERROR' && result.mensaje) {
            // Setear estado porque NO se valido el INE Ocr
            setValidateIdOcr(false);
            // Mostrar mensaje de consulta fallida
            setOpenSnackbar(true);
            setMessageSnackbar('No se ha logrado validar su documento INE');
            setSeveritySnackbar('error');
        }

        if (result.validacionMRZ) {
            console.log(result.validacionMRZ);
            // Setear estado porque SI se valido el INE Ocr
            setValidateIdOcr(true);
            // Mostrar mensaje de consulta exitosa
            // showStatusMessage(`Su ${result.tipo} de subtipo ${result.subTipo} se ha validado correctamente`, 'success');
            setOpenSnackbar(true);
            setMessageSnackbar(`¡Su ${result.tipo} se ha validado correctamente, ahora oprima el botón de guardar!`);
            setSeveritySnackbar('success');
        }

        // Remover spinner loading
        setLoading(false);

        // Remover boton de validar
        setConfirmValidation(false);

        // Setear a vacio el estado para remover los archivos
        setDocuments([]);

    }


    const saveIdOcr = async (event) => {
        // Verificar que se hayan agregado los documentos
        if (!saveDocuments[0] || !saveDocuments[1]) {
            showStatusMessage('¡Debes añadir tus dos documentos!', 'warning');
            return false;
        }

        // Sacar datos usaurio identificado del localstorage
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");

        // Comprobar si tengo el token y el user
        if (!token || !user) {
            return false;
        }


        // Verificar estado de validado
        console.log(validateIdOcr);

        // Preparar los datos a enviar, en el estado documents esta la informacion de la imagen de documento
        const dataDocument = new FormData();
        dataDocument.append('file0', saveDocuments[0], saveDocuments[0].name);
        dataDocument.append('file1', saveDocuments[1], saveDocuments[1].name);
        dataDocument.append('idUser', auth.id);
        dataDocument.append('validationINE', validateIdOcr);


        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer  ${token.replace(/['"]+/g, '')}`
            },
            body: dataDocument,
            redirect: 'follow'
        };

        try {
            const request = await fetch(`${Global.url_api}api/upload-ine`, requestOptions);
            const response = await request.json();

            console.log(response);
            showStatusMessage('¡INE almacenado correctamente!', 'success');

            // Recargar la pagina para reflejar cambios
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.log(error);
        }
    }


    // * CUSTOM BUTTON
    const CustomButton = styled(Button)(() => ({
        color: 'white',
        backgroundColor: '#3ad69b',
        '&:hover': {
            backgroundColor: '#32b986',
        },
    }));


    // * FUNCTIONS SNACKBAR
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const showStatusMessage = (message, type) => {
        setOpenSnackbar(true);
        setMessageSnackbar(message);
        setSeveritySnackbar(type);
        setTimeout(() => {
            closeSnackbar();
        }, 3000);
    };

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };


    // * FUNCTIONS MODAL
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    // * ACTIONS
    // SpeedDial
    const actionsSpeedDeal = [
        { icon: <DeleteOutlineOutlinedIcon onClick={handleOpen} />, name: 'Eliminar' },
        { icon: <CloudDownloadOutlinedIcon />, name: 'Descargar' },
    ];

    return (
        <div>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                <Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>
            <Container component="main" maxWidth="lg" sx={{ mt: 7 }}>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', padding: '15px', borderRadius: '15px' }} >

                    <Grid container spacing={2} sx={{ mt: -1, width: '100%' }} >
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                            <Link to="/onboarding" className="onboarding-item-text">
                                <CustomButton variant="contained" startIcon={<CloudUploadIcon />} >Continuar subiendo documentos ocr2</CustomButton>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                            <SpeedDial
                                FabProps={{
                                    sx: {
                                        bgcolor: '#3ad69b',
                                        '&:hover': {
                                            bgcolor: '#32b986',
                                        }
                                    }
                                }}
                                ariaLabel="SpeedDial basic example"
                                direction={direction}
                                icon={<SpeedDialIcon />}
                            >
                                {actionsSpeedDeal.map((action) => (
                                    <SpeedDialAction
                                        key={action.name}
                                        icon={action.icon}
                                        tooltipTitle={action.name}
                                    />
                                ))}
                                <SpeedDialAction
                                    key={"Editar"}
                                    icon={<CreateOutlinedIcon />}
                                    tooltipTitle={"Editar"} />
                                <SpeedDialAction
                                    onClick={handleOpen}
                                    key={"Ver"}
                                    icon={<VisibilityOutlinedIcon />}
                                    tooltipTitle={"Ver"} />
                            </SpeedDial>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                            <Typography variant="subtitle2">
                                {auth.name}, en el siguiente apartado, puedes seleccionar tu INE.
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* Spinner Loader para procesar peticiones */}
                    {
                        (loading === true) &&
                        <>
                            <Box sx={{ mt: 1 }}>
                                <Box sx={{ display: 'flex' }}>
                                    <CircularProgress />
                                </Box>
                            </Box>
                        </>
                    }

                    <Grid container spacing={2} sx={{ mt: -1, width: '100%' }} >
                        <Grid align="start" item xs={12} md={6} sx={{ mt: 2, textAlign: 'center' }}>
                            <MuiFileInput value={fileOne} onChange={event => processFilesInBase64(event, 1)} placeholder='Agregar INE Frente' />
                        </Grid>
                        <Grid align="start" item xs={12} md={6} sx={{ mt: 2, textAlign: 'center' }}>
                            <MuiFileInput value={fileTwo} onChange={event => processFilesInBase64(event, 2)} placeholder='Agregar INE Reverso' />
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            {
                                confirmValidation &&
                                <>
                                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                                        <CustomButton onClick={validateIdOcrByNubarium} variant="contained" >Validar</CustomButton>
                                    </Stack>
                                </>
                            }
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                                <CustomButton onClick={saveIdOcr} variant="contained" endIcon={<SaveIcon />} >Guardar</CustomButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        INE Ocr
                    </Typography>
                    <Typography component={'span'} id="modal-modal-description" sx={{ mt: 2 }}>
                        <Grid container spacing={2} sx={{ mt: -1, width: '100%' }} >
                            <Grid item xs={12}>
                                <img src={`${Global.url_api_root}storage/app/documents/ine/${onboarding.data.img_ine_frente}`} alt={onboarding.data.img_ine_frente} className="img-ine-modal" />
                            </Grid>
                            <Grid item xs={12}>
                                <img src={`${Global.url_api_root}storage/app/documents/ine/${onboarding.data.img_ine_reverso}`} alt={onboarding.data.img_ine_reverso} className="img-ine-modal" />
                            </Grid>
                        </Grid>
                    </Typography>
                </Box>
            </Modal>


        </div>
    )
}