import React, { useEffect, useState } from 'react';

// * IMPORT HOOKS CUSTOMS
import useAuth from '../../hooks/useAuth';
import { useAlert } from '../../hooks/useAlert';

// * IMPORTS REACT ROUTER
import { Link } from 'react-router-dom';

// * IMPORTS HELPERS
import { Global } from '../../helpers/Global';

// * MEDIA CUSTOM
import imgDefault from '../../assets/img/general/default.png';

// * IMPORTS MUI FILE INPUT
import { MuiFileInput } from 'mui-file-input'

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// * IMPORTS MATERIAl UI - DIALOG
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// * IMPORTS MATERIAL UI - ICONS
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// * IMPORTS MATERIAl UI - SPEED DIAL
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

// * IMPORT MATERIAL - BUTTONS AND ICON
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Stack from '@mui/material/Stack';

// * IMPORTS MATERIAL UL - PROGRESS SPINNER
import CircularProgress from '@mui/material/CircularProgress';

// * IMPORT MATERIAL UI - ALERT TOAST
import Snackbar from '@mui/material/Snackbar';

// * IMPORTS FORM
import Typography from '@mui/material/Typography';

// * MATERIAL UI - CUSTOM STYLES
import { styled } from '@mui/material/styles';




export const DocumentBill = () => {

	// * HOOKS
	// Conseguir los datos de usuario y onboarding
	const { auth, onboarding } = useAuth();
	// MUI file input
	const [fileOne, setFileOne] = useState(null);
	// Factura
	const [confirmValidation, setConfirmValidation] = useState(false);
	const [validateBill, setValidateBill] = useState(false);
	const [documentsBill, setDocumentsBill] = useState({
		frente: {
			base64: null,
			file: null
		},
		reverso: {
			base64: null,
			file: null
		}
	});
	// Loading
	const [loading, setLoading] = useState(false);
	// Speeddeal
	const [direction] = useState('left');
	// Modal - Dialog
	const [openDialog, setOpenDialog] = useState(false);
	// Alerts Message
	const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();


	// Se ejecutara el Hook en cada ocacion que se actualice el estado de documents
	useEffect(() => {
		if (documentsBill.frente.file !== null) {
			// Para este documento el valor por defecto sera false, ya que no se cuenta con validacion automatica y no tiene caso mostrar el boton de validar
			// Pero se queda indicada la funcion por si mas adelante se desarrolla la validacion
			setConfirmValidation(false);
		}
		else {
			setConfirmValidation(false);
		}
	}, [documentsBill])


	const uploadFiles = (file, type) => {

		if (type === 'frente') {
			// Asignar valores al input del frente
			setFileOne(file);

			// Guardar objeto "file" en estado documentsBill
			setDocumentsBill({
				...documentsBill,
				frente: {
					...documentsBill.frente,
					file: file
				}
			});


		}

		// Poner a false el estado de validacion del documento para que no se quede con un valor previo
		setValidateBill(false);

	}


	const validateDocumentByNubarium = async () => {

		setLoading(true);

		let dataDocumentValidate = new FormData();
		dataDocumentValidate.append('frente', documentsBill.frente.file, documentsBill.frente.file.name);

		// Preparar peticion a API
		let requestOptions = {
			method: 'POST',
			headers: {

			},
			body: dataDocumentValidate,
			redirect: 'follow'
		};

		let request = await fetch(`${Global.url_api}api/validate-proof-address`, requestOptions);
		let response = await request.json();

		let result = JSON.parse(response);

		console.log(result);

		if (result.status === 'OK') {
			// Setear estado porque SI se valido el documento
			setValidateBill(true);
			// Mostrar mensaje de consulta exitosa
			showStatusMessage(`Factura validada correctamente, ahora oprima el botón de guardar.`, 'success');
		}

		if (result.estatus === 'ERROR' && result.mensaje) {
			// Setear estado porque NO se valido el documento
			setValidateBill(false);
			// Mostrar mensaje de consulta
			showStatusMessage(`No ha sido posible validar su Factura.`, 'error');
		}

		// Remover spinner loading
		setLoading(false);

		// Remover boton de validar
		setConfirmValidation(false);
	}


	const uploadServerDocument = async (event) => {

		// Verificar que se hayan agregado los documentos
		if (documentsBill.frente.file === null) {
			showStatusMessage('¡Debes agregar tu documento!', 'warning');
			return false;
		}

		setLoading(true);

		// Sacar datos usaurio identificado del localstorage
		const token = localStorage.getItem("token");
		const user = localStorage.getItem("user");

		// Comprobar si tengo el token y el user
		if (!token || !user) {
			return false;
		}


		// Verificar estado de validado
		console.log(validateBill);

		// Preparar los datos a enviar, en el estado documents esta la informacion de la imagen de documento
		const dataDocument = new FormData();
		dataDocument.append('file0', documentsBill.frente.file, documentsBill.frente.file.name);
		dataDocument.append('idUser', auth.id);
		dataDocument.append('validationBill', validateBill);


		let requestOptions = {
			method: 'POST',
			headers: {
				"Authorization": `Bearer  ${token.replace(/['"]+/g, '')}`
			},
			body: dataDocument,
			redirect: 'follow'
		};

		try {
			const request = await fetch(`${Global.url_api}api/upload-bill`, requestOptions);
			const response = await request.json();

			console.log(response);

			if (response.code === '201') {
				showStatusMessage('¡Documento guardado correctamente!', 'success');
			}
			else if (response.code === '404') {
				showStatusMessage('Se produjo un error inesperado, el archivo debe ser de tipo:  jpg, jpeg, png.', 'error');
				setLoading(false);
				return false;
			}

			setLoading(false);

			// Redireccionar a onboarding para seguir subiendo documentos
			setTimeout(() => {
				window.location.href = `${Global.url_app}onboarding`;
			}, 3000);

		} catch (error) {
			console.log(error);
		}
	}


	// * CUSTOM BUTTON
	const CustomButton = styled(Button)(() => ({
		color: 'white',
		backgroundColor: '#3ad69b',
		'&:hover': {
			backgroundColor: '#32b986',
		},
	}));


	// * DIALOG
	const clickOpenDialog = () => {
		setOpenDialog(true);
	};

	const closeDialog = () => {
		setOpenDialog(false);
	};


	return (
		<div>
			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
					{messageSnackbar}
				</Alert>
			</Snackbar>

			<Container component="main" maxWidth="lg" sx={{ mt: 7 }}>

				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', padding: '15px', borderRadius: '15px' }} >

					<Grid container spacing={2} sx={{ mt: -1, width: '100%' }} >
						<Grid item xs={12} sm={12} md={7} lg={7}>
							<Link to="/onboarding" className="onboarding-item-text">
								<CustomButton variant="contained" startIcon={<CloudUploadIcon />} >Continuar subiendo documentos</CustomButton>
							</Link>
						</Grid>
						<Grid item xs={12} sm={12} md={5} lg={5}>
							<SpeedDial
								FabProps={{
									sx: {
										bgcolor: '#3ad69b',
										'&:hover': {
											bgcolor: '#32b986',
										}
									}
								}}
								ariaLabel="SpeedDial basic example"
								direction={direction}
								icon={<SpeedDialIcon />}
							>
								<SpeedDialAction
									onClick={clickOpenDialog}
									key={"Ver"}
									icon={<VisibilityOutlinedIcon />}
									tooltipTitle={"Ver"} />
							</SpeedDial>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Typography variant="h6">
								{auth.name}, en el siguiente apartado puedes tu factura(jpeg, jpg, png).
							</Typography>
						</Grid>
					</Grid>

					{/* Spinner Loader para procesar peticiones */}
					{
						(loading === true) &&
						<>
							<Box sx={{ mt: 1 }}>
								<Box sx={{ display: 'flex' }}>
									<CircularProgress />
								</Box>
							</Box>
						</>
					}

					<Grid container spacing={2} sx={{ mt: -1, width: '100%' }} >
						<Grid align="start" item xs={12} md={12} sx={{ mt: 2, textAlign: 'center' }}>
							<MuiFileInput value={fileOne} onChange={event => uploadFiles(event, 'frente')} placeholder='Agregar Factura' />
						</Grid>

						<Grid item xs={12} md={12} sx={{ mt: 2 }}>
							{
								confirmValidation &&
								<>
									<Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
										<CustomButton onClick={validateDocumentByNubarium} disabled={loading} variant="contained" endIcon={<CheckCircleOutlineIcon />} >Validar</CustomButton>
									</Stack>
								</>
							}
						</Grid>

						<Grid item xs={12} md={12} sx={{ mt: 2 }}>
							<Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
								<CustomButton onClick={uploadServerDocument} disabled={loading} variant="contained" endIcon={<SaveIcon />} >Guardar</CustomButton>
							</Stack>
						</Grid>
					</Grid>
				</Box>
			</Container>


			<Dialog
				open={openDialog}
				onClose={closeDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{auth.name}, a continuación te mostramos el documento que guardaste como factura.
				</DialogTitle>
				<DialogContent>
					<Box
						component="img"
						sx={{
							maxWidth: "100%", maxHeight: "calc(100vh - 64px)"
						}}
						alt={onboarding.data.img_factura}
						src={onboarding.data.img_factura !== null && onboarding.data.img_factura !== "" ? `${Global.url_api_root}storage/app/documents/factura/${onboarding.data.img_factura}` : imgDefault}
					/>
				</DialogContent>

				<DialogActions>
					<Button onClick={closeDialog}>Cerrar</Button>
				</DialogActions>
			</Dialog>

		</div>
	)
}
