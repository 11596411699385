import React, { useState, useEffect } from 'react';
import { useForm } from '../../hooks/useForm';
import useAuth from '../../hooks/useAuth';

// * IMPORTS ROUTING 
// import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// * IMPORT MEDIA CUSTOM
import logoSpingo from '../../assets/img/general/Logo_ 2.png';

// * IMPORTS HELPERS
import { Global } from '../../helpers/Global';

// * IMPORTS UTIL FROM API GOOGLE
import jwt_decode from "jwt-decode";

// * IMPORTS MATERIAL UI - REGISTER
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// * MATERIAL UI - CUSTOM STYLES
import { styled } from '@mui/material/styles';

// * IMPORT MATERIAL UI ALERT TOAST
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const theme = createTheme();

export const Register = () => {

	// * HOOKS
	// Auth
	const { setAuth } = useAuth();
	// Register User
	const { form, changed } = useForm({});
	// Snackbar
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [messageSnackbar, setMessageSnackbar] = useState("");
	const [severitySnackbar, setSeveritySnackbar] = useState("");


	// * GOOGLE
	useEffect(() => {
		/* global google */
		google.accounts.id.initialize({
			client_id: `${Global.client_id_google}`,
			callback: handleCallbackResponseGoogle
		})

		google.accounts.id.renderButton(
			document.getElementById("signInDiv"),
			{ theme: "outline", size: "large" }
		);

		google.accounts.id.prompt();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleCallbackResponseGoogle = async (response) => {

		if (response.hasOwnProperty('credential')) {

			let userObj = jwt_decode(response.credential);

			// Prepara request api para hacer registro o login con Google
			let requestOptionsGoogle = {
				method: 'POST',
				body: JSON.stringify(userObj),
				headers: {
					"Content-Type": "application/json"
				},
				redirect: 'follow'
			};

			// Peticion al backend
			let request = await fetch(`${Global.url_api}api/auth/google-register-login`, requestOptionsGoogle);

			let data = await request.json();

			console.log(data);

			// Peticion al backend para obtener los datos del usuario
			const requestMeGoogle = await fetch(`${Global.url_api}api/auth/me`, {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer  ${data.access_token.replace(/['"]+/g, '')}`
				},
				body: {},
				redirect: 'follow'
			});

			const dataMe = await requestMeGoogle.json();

			// Persistir los datos en el navegador
			localStorage.setItem("token", data.access_token);
			localStorage.setItem("user", JSON.stringify(dataMe));

			// Setear los datos en el auth de AuthProvider
			setAuth(dataMe);

			// Redireccion a GUI Onboarding
			setTimeout(() => {
				window.location.reload();
			}, 1500);
		} else {
			console.log('error login google');
			alert('Error al iniciar sesión con Google, favor de intentar más tarde.');
		}

	}

	// * HUBSPOT
	// Get document type from url
	const { name_hubspot, email_hubspot, telephone_hubspot } = useParams();

	// Si viene directo de HubSpot capturamos sus datos para autollenar el formulario
	useEffect(() => {
		if (name_hubspot !== undefined && email_hubspot !== undefined && telephone_hubspot !== undefined) {
			document.getElementById('name').value = name_hubspot;
			document.getElementById('email').value = email_hubspot;
			document.getElementById('celular').value = telephone_hubspot;
		}
	}, [name_hubspot, email_hubspot, telephone_hubspot])


	// * FUNCTION REGISTER USER
	const saveUser = async (event) => {
		// Prevenir actualizar pantalla
		event.preventDefault();

		// Recoger datos del formulario
		let newUser = form;
		newUser.fecha_nac = "1997/08/16";


		let { name, middle_name, last_name, celular, email, password, password_repet } = newUser;

		// Si los datos generales vienen de hubspot
		if (name_hubspot !== undefined && email_hubspot !== undefined && telephone_hubspot !== undefined) {
			newUser.name = document.getElementById('name').value
			newUser.celular = document.getElementById('celular').value
			newUser.email = document.getElementById('email').value
		}



		if (!name || !middle_name || !middle_name || !last_name || !celular || !email || !password || !password_repet) {
			showStatusMessage('Todos los campos son obligatorios.', 'warning');
			return false;
		}

		// Validaciones de password - longitud
		if (password.length < 6 || password_repet.length < 6) {
			showStatusMessage('La contraseña debe tener al menos 6 caracteres.', 'warning');
			return false;
		}

		// Validaciones de password - iguales
		if (password !== password_repet) {
			showStatusMessage('La contraseña de verificación no coincide.', 'warning');
			return false;
		}


		// Pasar nombre completo a mayusculas
		newUser.name = newUser.name.toUpperCase();
		newUser.middle_name = newUser.middle_name.toUpperCase();
		newUser.last_name = newUser.last_name.toUpperCase();

		// Prepara request api para hacer el registro
		let requestOptionsRegister = {
			method: 'POST',
			body: JSON.stringify(newUser),
			headers: {
				"Content-Type": "application/json"
			},
			redirect: 'follow'
		};

		// Guardar usuario en el backend
		let request = await fetch(`${Global.url_api}api/auth/register`, requestOptionsRegister);

		let data = await request.json();

		console.log(data);

		// Validar respuestas del backend
		if (data.message && data.message === 'success create user') {
			showStatusMessage('¡Registro Exitoso!', 'success');

			// El registro fue exitoso, ahora hacemos login
			let userToLogin = {
				email: email,
				password: password
			}

			// Prepara request api para hacer login
			let requestOptionsLogin = {
				method: 'POST',
				body: JSON.stringify(userToLogin),
				headers: {
					"Content-Type": "application/json"
				},
				redirect: 'follow'
			};

			// Peticion al backend
			let request = await fetch(`${Global.url_api}api/auth/login`, requestOptionsLogin);

			let data = await request.json();

			// Prepara request api
			let requestOptionsMe = {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer  ${data.access_token.replace(/['"]+/g, '')}`
				},
				body: {},
				redirect: 'follow'
			};

			// Peticion al backend para obtener los datos del usuario
			const requestMe = await fetch(`${Global.url_api}api/auth/me`, requestOptionsMe);

			const dataMe = await requestMe.json();

			// Persistir los datos en el navegador
			localStorage.setItem("token", data.access_token);
			localStorage.setItem("user", JSON.stringify(dataMe));

			// Setear los datos en el auth de AuthProvider
			setAuth(dataMe);

			// Redireccion a GUI Onboarding
			setTimeout(() => {
				window.location.reload();
			}, 1500);
		}
		else if (data.email || data.password) {
			if (data.email) showStatusMessage(`${data.email[0]}`, 'error');
			if (data.password) showStatusMessage(`${data.password[0]}`, 'error');

			return false;
		} else {
			showStatusMessage('¡Error inesperado!', 'error');
		}
	};

	const validatePassword = (event) => {

		if (form.password === form.password_repet) {
			// showStatusMessage('¡Las contraseñas coinciden!', 'success');
		} else {
			// showStatusMessage('¡Las contraseñas no coinciden!', 'error');
		}

		setTimeout(function () {
			closeSnackbar();
		}, 1000);

	}


	// * CUSTOM BUTTON
	const CustomButton = styled(Button)(() => ({
		color: 'white',
		backgroundColor: '#3340E3',
		'&:hover': {
			backgroundColor: '#4d56cd',
		},
	}));

	// * FUNCTIONS SNACKBAR
	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	const showStatusMessage = (message, type) => {
		setOpenSnackbar(true);
		setMessageSnackbar(message);
		setSeveritySnackbar(type);
	};

	const closeSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};

	return (
		<ThemeProvider theme={theme}>

			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
					{messageSnackbar}
				</Alert>
			</Snackbar>

			<Container component="main" maxWidth="sm" sx={{marginTop:8}}>

				<CssBaseline />
				<Box className='register-login-card' sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: '55px', borderRadius: '10px'}} >

					<Box
						display="flex"
						justifyContent="start"
						alignItems="start"
						component="img"
						sx={{
							maxWidth: "25%", maxHeight: "calc(100vh - 64px)", marginBottom: 2
						}}
						alt='logo-spingo'
						src={logoSpingo}
					/>

					<Typography variant="h5" gutterBottom sx={{ mb: 0 }}>
						Te damos la bienvenida
					</Typography>

					<Typography variant="subtitle1" gutterBottom sx={{ mb: 2 }}>
						tunombre@gmail.com
					</Typography>

					<Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
						Llena el siguiente formulario para abrir una cuenta y subir tus documentos.
					</Typography>

					{/* REGISTER WITH API GOOGLE */}
					<Grid container>
						<Grid item xs={12} sx={{
							display: 'flex',
							justifyContent: 'center',
						}}>
							<div id="signInDiv"></div>
						</Grid>
					</Grid>

					<Box component="form" onSubmit={saveUser} noValidate sx={{ mt: 1 }}>

						<TextField color='secondary' onChange={changed} error={!form.name ? true : false} helperText={!form.name ? "Campo obligatorio" : false} margin="normal" required fullWidth id="name" label="Nombre" name="name" autoComplete="off" autoFocus size="small" />

						<TextField color='secondary' onChange={changed} error={!form.middle_name ? true : false} helperText={!form.middle_name ? "Campo obligatorio" : false} margin="normal" required fullWidth id="middle_name" label="Apellido Paterno" name="middle_name" autoComplete="off" size="small" />

						<TextField color='secondary' onChange={changed} error={!form.last_name ? true : false} helperText={!form.last_name ? "Campo obligatorio" : false} margin="normal" required fullWidth id="last_name" label="Apellido Materno" name="last_name" autoComplete="off" size="small" />

						<TextField color='secondary' onChange={changed} error={!form.celular ? true : false} helperText={!form.celular ? "Campo obligatorio" : false} margin="normal" required fullWidth id="celular" label="Celular" name="celular" autoComplete="off" type="number" size="small" />

						<TextField color='secondary' onChange={changed} error={!form.email ? true : false} helperText={!form.email ? "Campo obligatorio" : false} margin="normal" required fullWidth id="email" label="Correo Electrónico" name="email" autoComplete="email" size="small" />

						<TextField color='secondary' onChange={changed} error={!form.password ? true : false} helperText={!form.password ? "Campo obligatorio" : false} margin="normal" required fullWidth name="password" label="Contraseña (mínimo 6 caracteres)" type="password" id="password" autoComplete="current-password" size="small" />

						<TextField color='secondary' onKeyPress={event => validatePassword(event)} onChange={changed} error={!form.password_repet ? true : false} helperText={!form.password_repet ? "Campo obligatorio" : false} margin="normal" required fullWidth name="password_repet" label="Confirmar contraseña" type="password" id="password_repet" autoComplete="current-password" size="small" />

						<CustomButton type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Crear cuenta</CustomButton>
					</Box>
				</Box>

			</Container>

		</ThemeProvider>
	)
}
