import React, { useEffect, useState } from 'react';

// * IMPORTS HELPERS
import { Global } from '../../../../helpers/Global';
// import { useAlert } from '../../../hooks/useAlert';

// * IMPORT MEDIA CUSTOM
import INE from '../../../../assets/img/iconos/INE.png';

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// * IMPORT MATERIAL UI
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

// * IMPORTS MATERIAL UI - ICONS
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

// * IMPORTS MATERIAL UI - ALERTS
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

// * MATERIAL UI - CUSTOM STYLES
import { styled } from '@mui/material/styles';

// * IMPORTS REACT FILEPOND
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const Identification = ({ idUser }) => {

    useEffect(() => {
        console.log(`Identification`);
    }, [])

    const [identificationFront, setidentificationFront] = useState([]);
    const [identificationReverse, setidentificationReverse] = useState([]);

    // Buttons
    const [disabledButton, setDisabledButton] = useState(false);
    // Alerts Message
    const [messages, setMessages] = useState({
        "validate_identification": "",
        "save_identification": "",
    });
    const [openAlert, setOpenAlert] = useState(true);
    // Progress
    const [progress, setProgress] = useState(false);

    // *  FUNCTIONS
    const uploadDocument = async (event) => {

        event.preventDefault();

        // Activar progress
        setProgress(true);

        // Reiniciar mensajes
        setMessages({
            "validate_identification": "",
            "save_identification": "",
        });

        let data = new FormData();
        data.append('idUser', idUser);

        // Conseguir el token de usuario
        const token = localStorage.getItem("token");

        try {

            // Validar campos de archivos
            if (identificationFront.length === 0 || identificationReverse.length === 0) {
                // showStatusMessage(`Documentos incompletos.`, 'warning');
                alert('Documentos incompletos.');
                return false;
            }

            if (identificationFront[0].file.type !== 'image/png' && identificationFront[0].file.type !== 'image/jpeg' && identificationFront[0].file.type !== 'image/jpg') {
                // showStatusMessage(`El frente de la identificación no es del tipo: png, jpeg, jpg.`, 'warning');
                alert('El frente de la identificación no es del tipo: png, jpeg, jpg.');
                return false;
            }

            if (identificationReverse[0].file.type !== 'image/png' && identificationReverse[0].file.type !== 'image/jpeg' && identificationReverse[0].file.type !== 'image/jpg') {
                // showStatusMessage(`El reverso de la identificación no es del tipo: png, jpeg, jpg.`, 'warning');
                alert('El reverso de la identificación no es del tipo: png, jpeg, jpg.');
                return false;
            }

            // validate identification
            data.append('frente', identificationFront[0].file, identificationFront[0].file.name);
            data.append('reverso', identificationReverse[0].file, identificationReverse[0].file.name);
            // save identification
            data.append('file0', identificationFront[0].file, identificationFront[0].file.name);
            data.append('file1', identificationReverse[0].file, identificationReverse[0].file.name);

            // Desactivar boton
            setDisabledButton(true);

            let requestIdentification = await fetch(`${Global.url_api}api/validate-ine-ocr`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json"
                },
                body: data,
                redirect: 'follow'
            });
            let responseIdentification = JSON.parse(await requestIdentification.json());

            console.log('@VALIDATE-IDENTIFICATION', responseIdentification);

            if (responseIdentification === false) {
                // showStatusMessage(`El servicio para validar y guardar su documento no está disponible por el momento, intente más tarde.`, 'error');
                alert(`El servicio para validar y guardar su documento no está disponible por el momento, intente más tarde.`);
                return false;
            }

            if (responseIdentification.validacionMRZ) {

                setMessages(prev => ({ ...prev, ...{ validate_identification: `Identificación ${responseIdentification.tipo} validada correctamente.` } }));

                data.append('validationINE', true);

                // Guardar identificacion
                let requestSaveIdentification = await fetch(`${Global.url_api}api/upload-ine`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer  ${token}`
                    },
                    body: data,
                    redirect: 'follow'
                });

                let responseSaveIdentification = await requestSaveIdentification.json();

                console.log('@SAVE-IDENTIFICATION', responseSaveIdentification);

                if (responseSaveIdentification.code === '201') {
                    setMessages(prev => ({ ...prev, ...{ save_identification: `Identificación guardada correctamente.` } }));

                } else {
                    setMessages(prev => ({ ...prev, ...{ save_identification: `Error al guardar identificación.` } }));
                }

            } else {
                setMessages(prev => ({ ...prev, ...{ validate_identification: `La identificación no se logro validar y no se ha guardado.` } }));
            }

            // Reiniciar estados
            setidentificationFront([]);
            setidentificationReverse([]);

        } catch (error) {
            console.log(error);
            // showStatusMessage(`Error Server ${error}`, 'error');
            alert(`Error Server ${error}`);
        }

        // Mostrar mensajes
        setOpenAlert(true);
        console.log('@MESSAGES', messages);

        // Activar boton
        setDisabledButton(false);

        // Desactivar progress
        setProgress(false);
    }

    // * CUSTOM BUTTON
    const CustomButton = styled(Button)(() => ({
        color: 'white',
        backgroundColor: '#3340E3',
        '&:hover': {
            backgroundColor: '#4d56cd',
        },
    }));


    return (
        <>
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            component="img"
                            sx={{
                                maxWidth: "100%", maxHeight: "calc(100vh - 64px)", marginLeft: 'auto', marginRight: 'auto', display: 'block', float: "left"
                            }}
                            alt='icono-spingo-identificacion'
                            src={INE}
                        />
                        <Typography sx={{ float: "left", ml: "15px" }} variant="h6" gutterBottom>
                            Subir Identificación
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        {progress &&
                            <Box mb={2} sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        }

                        {messages.validate_identification !== "" &&
                            <Collapse in={openAlert}>
                                <Alert
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="info"
                                            size="small"
                                            onClick={() => {
                                                setOpenAlert(false);
                                                setMessages({
                                                    "validate_identification": "",
                                                    "save_identification": "",
                                                });
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    variant="outlined"
                                    severity="info"
                                    sx={{ mb: 2 }}
                                >
                                    {messages.validate_identification}
                                </Alert>
                            </Collapse>
                        }

                        {messages.save_identification !== "" &&
                            <Collapse in={openAlert}>
                                <Alert
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="info"
                                            size="small"
                                            onClick={() => {
                                                setOpenAlert(false);
                                                setMessages({
                                                    "validate_identification": "",
                                                    "save_identification": "",
                                                });
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    variant="outlined"
                                    severity="info"
                                    sx={{ mb: 2 }}
                                >
                                    {messages.save_identification}
                                </Alert>
                            </Collapse>
                        }
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="body1" gutterBottom>
                            Frente de la identificación *
                        </Typography>

                        <FilePond
                            id="identificationFront"
                            files={identificationFront}
                            onupdatefiles={setidentificationFront}
                            labelIdle='Arrastra y suelta, o <span class="filepond--label-action">abrir archivos</span> <br> Archivo .jpg, .jpeg, .png de máximo 3 mb'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="body1" gutterBottom>
                            Reverso de la identificación *
                        </Typography>

                        <FilePond
                            id="identificationReverse"
                            files={identificationReverse}
                            onupdatefiles={setidentificationReverse}
                            labelIdle='Arrastra y suelta, o <span class="filepond--label-action">abrir archivos</span> <br> Archivo .jpg, .jpeg, .png de máximo 3 mb'
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3, mb: 2 }} >
                        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                            <CustomButton variant="contained" onClick={event => uploadDocument(event)} endIcon={<CloudUploadOutlinedIcon />} disabled={disabledButton} >Subir</CustomButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
