import React from 'react';

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { PawnAdjustmentSlip } from '../PawnAdjustmentSlip';


export const View = ({ open, onClose, data }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth={'lg'} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent>
                <PawnAdjustmentSlip pawnAdjustmentSlip={data} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
};
