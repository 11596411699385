import React, { useEffect, useState } from 'react';

// * IMPORT HOOKS CUSTOMS
import useAuth from '../../hooks/useAuth';
import { useAlert } from '../../hooks/useAlert';

// * IMPORTS REACT ROUTER
import { Link } from 'react-router-dom';

// * IMPORTS HELPERS
import { Global } from '../../helpers/Global';

// * MEDIA CUSTOM
import imgDefault from '../../assets/img/general/default.png';

// * IMPORTS MUI FILE INPUT
import { MuiFileInput } from 'mui-file-input'

// * IMPORTS MATERIAl UI - CONTAINER AND GRID
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// * IMPORTS MATERIAl UI - DIALOG
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// * IMPORTS MATERIAL UI - ICONS
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// * IMPORTS MATERIAl UI - SPEED DIAL
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

// * IMPORT MATERIAL - BUTTONS AND ICON
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Stack from '@mui/material/Stack';

// * IMPORTS MATERIAL UL - PROGRESS SPINNER
import CircularProgress from '@mui/material/CircularProgress';

// * IMPORT MATERIAL UI - ALERT TOAST
import Snackbar from '@mui/material/Snackbar';

// * IMPORTS FORM
import Typography from '@mui/material/Typography';

// * MATERIAL UI - CUSTOM STYLES
import { styled } from '@mui/material/styles';




export const DocumentIdentification = () => {

    // * HOOKS
    // Conseguir los datos de usuario y onboarding
    const { auth, onboarding } = useAuth();
    // MUI file input
    const [fileOne, setFileOne] = useState(null);
    const [fileTwo, setFileTwo] = useState(null)
    // INE Ocr
    const [confirmValidation, setConfirmValidation] = useState(false);
    const [validateIdOcr, setValidateIdOcr] = useState(false);
    const [documentsOcr, setDocumentsOcr] = useState({
        frente: {
            base64: null,
            file: null
        },
        reverso: {
            base64: null,
            file: null
        }
    });
    // Loading
    const [loading, setLoading] = useState(false);
    // Speeddeal
    const [direction] = useState('left');
    // Modal - Dialog
    const [openDialog, setOpenDialog] = useState(false);
    // Alerts Message
    const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();


    // Se ejecutara el Hook en cada ocacion que se actualice el estado de documents
    useEffect(() => {
        if (documentsOcr.frente.file !== null && documentsOcr.reverso.file !== null) {
            setConfirmValidation(true);
            // console.log('Ya puedes validar xD');
        }
        else {
            setConfirmValidation(false);
            // console.log('te la pelaste xD');
        }
    }, [documentsOcr])


    const uploadFiles = (file, type) => {

        if (type === 'frente') {
            // Asignar valores al input del frente
            setFileOne(file);

            // Guardar objeto "file" en estado documentsOcr
            setDocumentsOcr({
                ...documentsOcr,
                frente: {
                    ...documentsOcr.frente,
                    file: file
                }
            });


        } else if (type === 'reverso') {
            // Asignar valores al input del reverso
            setFileTwo(file);

            // Guardar objeto "file" en estado documentsOcr
            setDocumentsOcr({
                ...documentsOcr,
                reverso: {
                    ...documentsOcr.reverso,
                    file: file
                }
            });
        }

        // Poner a false el estado de validacion del documento para que no se quede con un valor previo
        setValidateIdOcr(false);

    }


    const validateDocumentByNubarium = async () => {

        setLoading(true);

        let dataDocumentOcr = new FormData();
        dataDocumentOcr.append('idUser', auth.id);
        dataDocumentOcr.append('frente', documentsOcr.frente.file, documentsOcr.frente.file.name);
        dataDocumentOcr.append('reverso', documentsOcr.reverso.file, documentsOcr.reverso.file.name);

        // Preparar peticion a API
        let requestOptions = {
            method: 'POST',
            headers: {

            },
            body: dataDocumentOcr,
            redirect: 'follow'
        };

        let request = await fetch(`${Global.url_api}api/validate-ine-ocr`, requestOptions);
        let response = await request.json();

        let result = JSON.parse(response);

        console.log(result);
        if (result === false) {
			showStatusMessage(`El servicio para validar y guardar su documento no está disponible por el momento, intente más tarde.`, 'error');
			return false;
		}

        if (result.validacionMRZ) {
            console.log(result.validacionMRZ);
            // Setear estado porque SI se valido el INE Ocr
            setValidateIdOcr(true);
            // Mostrar mensaje de consulta exitosa
            showStatusMessage(`¡Su ${result.tipo} se validó  correctamente, guardando...!`, 'success');
            // Guardar documentos validados
            setTimeout(() => {
                uploadServerDocument(true);
            }, 5500);
        }

        if (!result.validacionMRZ && result.estatus !== 'ERROR') {
            // Setear estado porque NO se valido el INE Ocr
            setValidateIdOcr(false);
            // Mostrar mensaje de consulta
            showStatusMessage(`Solo se logró validar el frente de su ${result.tipo}, vuelva a cargar el reverso, por favor.`, 'warning');
        }

        if (result.estatus === 'ERROR' && result.mensaje) {
            // Setear estado porque NO se valido el INE Ocr
            setValidateIdOcr(false);
            // Mostrar mensaje de consulta
            showStatusMessage('No se ha logrado validar su documento IFE/INE', 'error');
        }

        // Remover spinner loading
        setLoading(false);

        // Remover boton de validar
        setConfirmValidation(false);
    }


    const uploadServerDocument = async (validateId) => {

        // Verificar estado de validado
        console.log(validateId);

        // Verificar que se hayan agregado los documentos
        if (documentsOcr.frente.file === null || documentsOcr.reverso.file === null) {
            showStatusMessage('¡Debes agregar tus dos documentos!', 'warning');
            return false;
        }

        setLoading(true);

        // Sacar datos usaurio identificado del localstorage
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");

        // Comprobar si tengo el token y el user
        if (!token || !user) {
            return false;
        }

        // Preparar los datos a enviar, en el estado documents esta la informacion de la imagen de documento
        const dataDocument = new FormData();
        dataDocument.append('file0', documentsOcr.frente.file, documentsOcr.frente.file.name);
        dataDocument.append('file1', documentsOcr.reverso.file, documentsOcr.reverso.file.name);
        dataDocument.append('idUser', auth.id);
        dataDocument.append('validationINE', validateId);


        let requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer  ${token.replace(/['"]+/g, '')}`
            },
            body: dataDocument,
            redirect: 'follow'
        };

        try {
            const request = await fetch(`${Global.url_api}api/upload-ine`, requestOptions);
            const response = await request.json();

            console.log(response);
            
            if (response.code === '201') {
				showStatusMessage('¡Documento guardado correctamente!', 'success');
			}
			else if (response.code === '404') {
				showStatusMessage('Se produjo un error inesperado, el archivo debe ser de tipo:  jpg, jpeg, png.', 'error');
                setLoading(false);
                return false;
			}

            setLoading(false);

            // Redireccionar a onboarding para seguir subiendo documentos
            setTimeout(() => {
                window.location.href = `${Global.url_app}onboarding`;
            }, 3000);
            
        } catch (error) {
            console.log(error);
        }
    }


    // * CUSTOM BUTTON
    const CustomButton = styled(Button)(() => ({
        color: 'white',
        backgroundColor: '#3ad69b',
        '&:hover': {
            backgroundColor: '#32b986',
        },
    }));


    // * DIALOG
    const clickOpenDialog = () => {
        setOpenDialog(true);
    };

    const closeDialog = () => {
        setOpenDialog(false);
    };


    return (
        <div>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                <Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>
            {validateIdOcr}
            <Container component="main" maxWidth="lg" sx={{ mt: 7 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', padding: '15px', borderRadius: '15px' }} >

                    <Grid container spacing={2} sx={{ mt: -1, width: '100%' }} >
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                            <Link to="/onboarding" className="onboarding-item-text">
                                <CustomButton variant="contained" startIcon={<CloudUploadIcon />} >Continuar subiendo documentos</CustomButton>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                            <SpeedDial
                                FabProps={{
                                    sx: {
                                        bgcolor: '#3ad69b',
                                        '&:hover': {
                                            bgcolor: '#32b986',
                                        }
                                    }
                                }}
                                ariaLabel="SpeedDial basic example"
                                direction={direction}
                                icon={<SpeedDialIcon />}
                            >
                                <SpeedDialAction
                                    onClick={clickOpenDialog}
                                    key={"Ver"}
                                    icon={<VisibilityOutlinedIcon />}
                                    tooltipTitle={"Ver"} />
                            </SpeedDial>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                            <Typography variant="h6">
                                {auth.name}, en el siguiente apartado puedes agregar tu INE.
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* Spinner Loader para procesar peticiones */}
                    {
                        (loading === true) &&
                        <>
                            <Box sx={{ mt: 1 }}>
                                <Box sx={{ display: 'flex' }}>
                                    <CircularProgress />
                                </Box>
                            </Box>
                        </>
                    }

                    <Grid container spacing={2} sx={{ mt: -1, width: '100%' }} >
                        <Grid align="start" item xs={12} md={6} sx={{ mt: 2, textAlign: 'center' }}>
                            <MuiFileInput value={fileOne} onChange={event => uploadFiles(event, 'frente')} placeholder='Agregar INE Frente' />
                        </Grid>
                        <Grid align="start" item xs={12} md={6} sx={{ mt: 2, textAlign: 'center' }}>
                            <MuiFileInput value={fileTwo} onChange={event => uploadFiles(event, 'reverso')} placeholder='Agregar INE Reverso' />
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            {
                                confirmValidation &&
                                <>
                                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                                        <CustomButton onClick={validateDocumentByNubarium} disabled={loading} variant="contained" endIcon={<CheckCircleOutlineIcon />} >Validar y Guardar</CustomButton>
                                    </Stack>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Container>


            <Dialog
                open={openDialog}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {auth.name}, a continuación te mostramos el documento que guardaste como medio de identificación.
                </DialogTitle>
                <DialogContent>
                    <Box
                        component="img"
                        sx={{
                            maxWidth: "100%", maxHeight: "calc(100vh - 64px)"
                        }}
                        alt={onboarding.data.img_ine_frente}
                        src = {onboarding.data.img_ine_frente !== null && onboarding.data.img_ine_frente !== ""  ? `${Global.url_api_root}storage/app/documents/ine/${onboarding.data.img_ine_frente}` : imgDefault}
                    />
                    <Box
                        component="img"
                        sx={{
                            maxWidth: "100%", maxHeight: "calc(100vh - 64px)"
                        }}
                        alt={onboarding.data.img_ine_reverso}
                        src = {onboarding.data.img_ine_reverso !== null && onboarding.data.img_ine_reverso !== ""  ? `${Global.url_api_root}storage/app/documents/ine/${onboarding.data.img_ine_reverso}` : imgDefault}
                    />
                </DialogContent>
                
                <DialogActions>
                    <Button onClick={closeDialog}>Cerrar</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}