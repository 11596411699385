import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { useAlert } from '../../hooks/useAlert';
import MUIDataTable from "mui-datatables";
import { Global } from '../../helpers/Global';
import {
	Box,
	Container,
	Grid,
	Backdrop,
	CircularProgress,
	Snackbar,
	Tooltip,
	IconButton,
	Dialog,
	Divider,
	AppBar,
	Toolbar,
	Typography,
	Slide,
	Stack
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloseIcon from '@mui/icons-material/Close';
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es-mx';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});


export const DashboardLeads = () => {
	// * HOOKS
	// Conseguir los datos de usuario y onboarding
	const { auth } = useAuth();
	const navigate = useNavigate();

	// Conseguir todos los clientes del backoffice
	const [dataClients, setDataClients] = useState([]);
	// Conseguir los datos de un cliente
	const [dataClient, setDataClient] = useState({});
	// Conseguir el tamaño del arreglo de leads
	const [leadsLength, setLeadsLength] = useState(0);
	// Controlar dialog
	const [openDialogClient, setOpenDialogClient] = useState(false);
	// Buttons
	const [disabledButton, setDisabledButton] = useState(false);
	// Backdrop loader
	const [loader, setLoader] = useState(false);
	// Alerts Message
	const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();

	// Conseguir a todos los leads de hubspot
	useEffect(() => {
		// Verificamos que solo los usuarios con role "admin" puedan acceder
		if (auth.role !== 'admin') {
			navigate("/onboarding");
		}

		getLeadsHubspot().then(result => {

			let leads_temp = [];

			result.forEach(function (lead, index) {
				let leadProperties = JSON.parse(lead.data);
				let lead_temp = {
					id: lead.id,
					firstname: leadProperties.firstname && (leadProperties.firstname).toString().toUpperCase(),
					lastname: leadProperties.lastname && (leadProperties.lastname).toString().toUpperCase(),
					email: leadProperties.email,
					phone: leadProperties.phone,
					lifecyclestage: leadProperties.lifecyclestage,
					prestamo_tentativo_v2: leadProperties.prestamo_tentativo_v2,
					marca_del_vehiculo: leadProperties.marca_del_vehiculo && (leadProperties.marca_del_vehiculo).toString().toUpperCase(),
					ano_del_auto: leadProperties.ano_del_auto,
					version_del_auto: leadProperties.version_del_auto && (leadProperties.version_del_auto).toString().toUpperCase(),
					modelo: leadProperties.modelo && (leadProperties.modelo).toString().toUpperCase(),
					datos_del_vehiculo: leadProperties.datos_del_vehiculo && (leadProperties.datos_del_vehiculo).toString().toUpperCase(),
					// hs_analytics_first_url: leadProperties.hs_analytics_first_url,
					created_at: lead.created_at,
				}
				leads_temp.push(lead_temp);
			});

			// Asignar el tamaño del arreglo de Leads al estado
			setLeadsLength(leads_temp.length)

			setDataClients(leads_temp);
		});
	}, [auth, navigate]);

	const getLeadsHubspot = async () => {
		// Preparar peticion a API

		// Conseguir el token de usuario
		const token = localStorage.getItem("token");

		let requestOptions = {
			method: 'GET',
			headers: {
				"Authorization": `Bearer  ${token}`
			},
			redirect: 'follow'
		};

		let request = await fetch(`${Global.url_api}api/auth/leads-workflow-life-cycle-stage`, requestOptions);
		let response = await request.json();

		return response.data;
	}

	const sendLeadBackOffice = async (event, lead) => {
		event.preventDefault();

		// Desactivar boton
		setDisabledButton(true);
		// Activar backdrop loader
		setLoader(true);

		let user = {
			"name": lead[1],
			"middle_name": lead[2],
			"last_name": '',
			"email": lead[3],
			"telephone": (lead[4] !== undefined && lead[4] !== 'undefined') ? lead[4] : '',
			"hubspot_simulator": {
				tentative_loan: lead[6],
				car_year: lead[7],
				car_brand: lead[8],
				car_version: lead[9],
			}
		};

		let data = new FormData();
		data.append('user', JSON.stringify(user));

		// Conseguir el token de usuario
		const token = localStorage.getItem("token");

		try {
			let request = await fetch(`${Global.url_api}api/auth/add-user-backoffice`, {
				method: 'POST',
				headers: {
					"Authorization": `Bearer  ${token}`
				},
				body: data,
				redirect: 'follow'
			});

			let response = await request.json();

			if (response.code === '201') {
				showStatusMessage(`Lead enviando a BackOffice SpinGo`, 'success');
			}
			else {
				showStatusMessage(`El Lead que buscas ya está disponible en el backoffice de SpinGo.`, 'warning');
			}

		} catch (error) {
			showStatusMessage(`Error Server ${error}`, 'error');
		}

		// Activar boton
		setDisabledButton(false);
		// Desactivar backdrop loader
		setLoader(false);
	}

	const columns = [
		{
			name: "",
			options: {
				filter: true,
				sort: false,
				empty: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<>
							<Grid container spacing={2}>
								<Grid item xs={2}>
									<Tooltip title="Enviar a backoffice SpinGo">
										<IconButton disabled={disabledButton} color='primary' aria-label="enviar-backoffice-spingo" size="medium" onClick={event => sendLeadBackOffice(event, tableMeta.rowData)}>
											<PersonAddIcon sx={{ color: '#3ad69b' }} fontSize="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</>
					);
				}
			}
		},
		{
			name: "firstname",
			label: "Nombre"
		},
		{
			name: "lastname",
			label: "Apellidos"
		},
		{
			name: "email",
			label: "Email"
		},
		{
			name: "phone",
			label: "Teléfono"
		},
		{
			name: "lifecyclestage",
			label: "Etapa del ciclo de vida"
		},
		{
			name: "prestamo_tentativo_v2",
			label: "Posible prestamo tentativo"
		},
		{
			name: "ano_del_auto",
			label: "Año del automóvil"
		},
		{
			name: "marca_del_vehiculo",
			label: "Marca del automóvil"
		},
		{
			name: "modelo",
			label: "Modelo del automóvil"
		},
		{
			name: "version_del_auto",
			label: "Versión del automóvil"
		},
		{
			name: "created_at",
			options: {
				customBodyRender: (value) => {
					return (
						<Moment locale="es-mx" format="D MMM YYYY" withTitle>
							{value}
						</Moment>
					);
				}
			},
			label: "Creado el"
		},
		{
			name: "id",
			label: "ID"
		},
	];

	const options = {
		// filterType: 'checkbox',
		textLabels: {
			body: {
				noMatch: "Lo sentimos, no se encontraron registros coincidentes.",
				toolTip: "Sort",
				columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			pagination: {
				next: "Siguiente pagina",
				previous: "Pagina anterior",
				rowsPerPage: "Filas por pagina:",
				displayRows: "de",
			},
			toolbar: {
				search: "Buscar",
				downloadCsv: "Descargar CSV",
				print: "Imprimir",
				viewColumns: "Ver columnas",
				filterTable: "Tabla de filtros",
			},
		}
	};

	return (
		<div>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loader}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
					{messageSnackbar}
				</Alert>
			</Snackbar>

			<Container component="main" maxWidth="xxl">
				{
					(leadsLength === 100) &&
					<Box sx={{ width: '100%' }} mt={2} mb={2}>
						<Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ width: '100%' }} >
							<Alert severity="info">Ya se alcanzaron 100 leads, es momento de actualizar el webservice.</Alert>
						</Stack>
					</Box>
				}

				<Box sx={{ width: '100%' }} mt={2}>
					<Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={12} sm={12} md={12} >
							<MUIDataTable
								title={"HubSpot Leads"}
								data={dataClients}
								columns={columns}
								options={options}
							/>
						</Grid>
						<Dialog
							fullScreen
							open={openDialogClient}
							onClose={event => { setOpenDialogClient(false) }}
							TransitionComponent={Transition}
						>
							<AppBar sx={{ position: 'relative', bgcolor: '#3340e3' }}>
								<Toolbar>
									<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
										Prospecto: {dataClient.name} {dataClient.middle_name} {dataClient.last_name}
									</Typography>
									<IconButton
										edge="start"
										color="inherit"
										onClick={(event) => {
											setDataClient({});
											setOpenDialogClient(false);
										}}
										aria-label="close"
									>
										<CloseIcon />
									</IconButton>
								</Toolbar>
							</AppBar>

							<Divider />
						</Dialog>
					</Grid>
				</Box>
			</Container>
		</div >
	)
}