import React, { useEffect, useState } from 'react';

import { Global } from '../../../../../helpers/Global';

import MUIDataTable from "mui-datatables";

import { Dialog, AppBar, Toolbar, IconButton, Typography, Slide, Tooltip } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RegisterAccess = ({ open, onClose }) => {

    const [registerAccess, setRegisterAccess] = useState([]);

    useEffect(() => {
        getRegisterAccess().then(result => {
            setRegisterAccess(result.data);
        });
    }, []);

    const getRegisterAccess = async () => {

        // Conseguir el token de usuario
        const token = localStorage.getItem("token");

        let request = await fetch(`${Global.url_api}api/auth/get-register-access-pawn-adjustment-slip`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer  ${token}`,
                "Content-Type": "application/json"
            },
            redirect: 'follow'
        });

        let response = await request.json();
        return response;
    }

    const columns = [
        {
            name: "id",
            label: "ID"
        },
        {
            name: "id_user",
            label: "Identificador de Usuario"
        },
        {
            name: "name_user",
            label: "Nombre"
        },
        {
            name: "created_at",
            label: "Fecha"
        },
    ];

    const options = {
        selectableRowsHideCheckboxes: true,
        filterType: 'textField',
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron registros coincidentes.",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
                next: "Siguiente pagina",
                previous: "Pagina anterior",
                rowsPerPage: "Filas por pagina:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Tabla de filtros",
            }
        }
    };

    const updateDataTable = () => {
        getRegisterAccess().then(result => {
            setRegisterAccess(result.data);
        });
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', bgcolor: '#3340e3' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Registro de Acceso a Contenido
                    </Typography>
                    <Tooltip title="Actualizar contenido">
                        <IconButton sx={{ color: 'white' }} aria-label="refresh-content" size="medium" onClick={updateDataTable}>
                            <RefreshIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <MUIDataTable
                title={"Registro de Acceso a Contenido"}
                data={registerAccess}
                columns={columns}
                options={options}
            />
        </Dialog>
    );
};

export default RegisterAccess;