import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

export const Logout = () => {

    const {setAuth, setOnboarding} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // Vaciar el localStorage
        localStorage.clear();

        // Setear estados globales a vacio
        setAuth({});
        setOnboarding({});

        // Navigate (redireccion) al login
        navigate("/login");
    }, [setAuth, setOnboarding, navigate]);

    return (
        <h6>Cerrando sesión</h6>
    )
}
