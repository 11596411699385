import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';

// * MATERIAL UI - TABS
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// * MATERIAL UI - ICONS
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';

// * MATERIAL UI - STTEPER
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

// * DOCUMENTS SOLICITUD DE CREDITO
import { DocumentIdOcr } from './DocumentIdOcr';
import { DocumentProofAddress } from './DocumentProofAddress';
import { DocumentBill } from './DocumentBill';
import { DocumentCirculationCard } from './DocumentCirculationCard';


const steps = ['INE/IFE', 'Comprobante de Domicilio', 'Factura', 'Tarjeta de Circulacion'];


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const OnboardingOp2 = () => {

    const [value, setValue] = React.useState(0);


    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const isStepFailed = (step) => {
        return step === 1;
    };

    return (
        <div>
            <Container maxWidth="xl">
                <Box sx={{ width: '100%', bgcolor: 'background.paper', mt: 5, borderRadius: '16px', p:1 }}>
                    <Stepper activeStep={1} orientation={windowSize.current[0] <= 500 ? 'vertical' : 'horizontal'}>
                        {steps.map((label, index) => {
                            const labelProps = {};
                            if (isStepFailed(index)) {
                                labelProps.optional = (
                                    <Typography variant="caption" color="error">
                                        No validado
                                    </Typography>
                                );

                                labelProps.error = true;
                            }

                            return (
                                <Step key={label}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
            </Container>

            <Container maxWidth="lg">
                <Box sx={{ width: '100%', bgcolor: 'background.paper', mt: 5, borderRadius: '16px' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile aria-label="basic tabs example">
                            <Tooltip title="Añadir INE/IFE" >
                                <Tab label="INE/IFE" icon={<BadgeOutlinedIcon />} {...a11yProps(0)} />
                            </Tooltip>
                            <Tooltip title="Añadir Comprobante de Domicilio" >
                                <Tab label="Comprobante de Domicilio" icon={<AddHomeWorkOutlinedIcon />}  {...a11yProps(1)} />
                            </Tooltip>
                            <Tooltip title="Añadir Factura" >
                                <Tab label="Factura" icon={<DirectionsCarFilledOutlinedIcon />}  {...a11yProps(2)} />
                            </Tooltip>
                            <Tooltip title="Añadir Tarjeta de Circulación" >
                                <Tab label="Tarjeta de Circulación" icon={<PlagiarismOutlinedIcon />} {...a11yProps(3)} />
                            </Tooltip>
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <DocumentIdOcr />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <DocumentProofAddress />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <DocumentBill />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <DocumentCirculationCard />
                    </TabPanel>
                </Box>
            </Container>
        </div>
    )
}

