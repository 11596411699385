import React, { useState } from 'react';

// * IMPORT MATERIAL UI - ALERT TOAST
import MuiAlert from '@mui/material/Alert';

export const useAlert = () => {
    
    // Snackbar
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [messageSnackbar, setMessageSnackbar] = useState("");
  	const [severitySnackbar, setSeveritySnackbar] = useState("");

    const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	const showStatusMessage = (message, type) => {
		setOpenSnackbar(true);
		setMessageSnackbar(message);
		setSeveritySnackbar(type);
		setTimeout(() => {
			closeSnackbar();
		}, 5000);
	};

	const closeSnackbar = (event, reason) => {
		if (reason === "clickaway") {
		  return;
		}
		setOpenSnackbar(false);
	};

    return {
        openSnackbar,
        messageSnackbar,
        severitySnackbar,
        showStatusMessage,
        closeSnackbar,
        Alert
    }
}