import React, { useState } from 'react';

import { AssignedAdjustmentSlip } from './helpers/AssignedAdjustmentSlip';
import { AssignOnePawnAdjustmentSlips } from './helpers/AssignOnePawnAdjustmentSlips';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';

export const AssignPawnAdjustmentSlips = ({ idProcessPawn, idPawnAdjustmentSlip, refreshContent }) => {

	// Render componenets child
	const [renderComponentChild, setRenderComponentChild] = useState('');
	const [openComponentChild, setOpenComponentChild] = useState(false);

	const actions = [
		{ icon: <AttachMoneyOutlinedIcon />, name: 'Abrir Boleta de Ajuste de Empeño', action: () => handleRenderComponentChild('assigned-adjustment-slip') },
		{ icon: <ContentPasteSearchOutlinedIcon />, name: 'Asignar Boleta de Ajuste de Empeño', action: () => handleRenderComponentChild('assign-adjustment-slip') },
	];

	const handleRenderComponentChild = (render) => {
		if (render === 'assigned-adjustment-slip') {
			setRenderComponentChild('assigned-adjustment-slip');

		} else if (render === 'assign-adjustment-slip') {
			setRenderComponentChild('assign-adjustment-slip');
		}
		setOpenComponentChild(true);
	}

	const resetComponentChild = () => {
		setOpenComponentChild(false);
		setRenderComponentChild('');
		// Indicamos al componente Pawn que ejecute su useEffect para actualizar su contenido
		refreshContent();
	}

	return (
		<>
			<SpeedDial
				ariaLabel="SpeedDial basic example"
				icon={<SpeedDialIcon />}
				direction={'left'}
				FabProps={{
					sx: {
						width: 40,
						height: 40,
						bgcolor: '#3ad69b',
						'&:hover': {
							bgcolor: '#37b988',
						},
					}
				}}
			>
				{actions.map((action) => (
					<SpeedDialAction
						key={action.name}
						icon={action.icon}
						tooltipTitle={action.name}
						onClick={action.action}
					/>
				))}
			</SpeedDial>
			{/* RENDERIZAR COMPONENTES HIJOS */}
			{(renderComponentChild === 'assigned-adjustment-slip') && <AssignedAdjustmentSlip open={openComponentChild} onClose={resetComponentChild} idProcessPawn={idProcessPawn} idPawnAdjustmentSlip={idPawnAdjustmentSlip} />}
			{(renderComponentChild === 'assign-adjustment-slip') && <AssignOnePawnAdjustmentSlips open={openComponentChild} onClose={resetComponentChild} idProcessPawn={idProcessPawn} />}
		</>
	)
}
