// npm install --save pizzip docxtemplater file-saver

import React from 'react';

import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver"

// Templates
import templateDocxContratos from '../../assets/docx/contrato.docx';

export const GenerateDocx = ({ data, template }) => {

	function loadFile(url, callback) {
		PizZipUtils.getBinaryContent(url, callback);
	}

	const generateDocument = () => {
		loadFile(
			template === 1 ? templateDocxContratos : 'none.docx',
			function (error, content) {
				if (error) {
					throw error;
				}
				const zip = new PizZip(content);
				const doc = new Docxtemplater(zip, {
					paragraphLoop: true,
					linebreaks: true,
				});

				// render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
				doc.render(data);
				const blob = doc.getZip().generate({
					type: "blob",
					mimeType:
						"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
				}); //Output the document using Data-URI
				saveAs(blob, `Contrato-${new Date().getTime()}.docx`);
			}
		);
	};

	return (
		<div>
			<Button onClick={generateDocument} variant="contained" startIcon={<SendIcon />}>
				Docx
			</Button>
		</div>
	)
}
