import React, { useEffect, useState } from 'react';

import { Global } from '../../../../helpers/Global';
import { jsPDF } from "jspdf";
import { View } from './helpers/View';
import { Editors } from './helpers/Editors';
import { Quotation1 } from './Quotation1';
import { Quotation2 } from './Quotation2';

import MUIDataTable from "mui-datatables";

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es-mx';

import { Dialog, AppBar, Toolbar, IconButton, Typography, Slide, Tooltip, Grid, Backdrop, CircularProgress, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ListPawnAdjustmentSlips = ({ open, onClose }) => {

	const [pawnAdjustmentSlips, setPawnAdjustmentSlips] = useState([]);
	const [data, setData] = useState({});
	const [idPawnAdjustmentSlip, setIdPawnAdjustmentSlip] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [renderComponentChild, setRenderComponentChild] = useState('');
	const [openComponentChild, setOpenComponentChild] = useState(false);


	useEffect(() => {
		getPawnAdjustmentSlips().then(result => {
			const newResult = checkProgressPercentage(result.data);
			setPawnAdjustmentSlips(newResult);
		});
	}, []);

	const getPawnAdjustmentSlips = async () => {

		// Conseguir el token de usuario
		const token = localStorage.getItem("token");

		let request = await fetch(`${Global.url_api}api/auth/get-pawn-adjustment-slips`, {
			method: 'GET',
			headers: {
				"Authorization": `Bearer  ${token}`,
				"Content-Type": "application/json"
			},
			redirect: 'follow'
		});

		let response = await request.json();
		return response;
	}

	const checkProgressPercentage = (result) => {
		result.forEach(pawn => {
			if (parseInt(pawn.existencia_initial) === 0 && parseInt(pawn.existencia_insurance) === 0) {
				pawn.percentage = 33;
			} else if (parseInt(pawn.existencia_initial) !== 0 && parseInt(pawn.existencia_insurance) === 0) {
				pawn.percentage = 66;
			} else if (parseInt(pawn.existencia_initial) === 0 && parseInt(pawn.existencia_insurance) !== 0) {
				pawn.percentage = 66;
			} else if (parseInt(pawn.existencia_initial) !== 0 && parseInt(pawn.existencia_insurance) !== 0) {
				// Ahora verificar que la boleta de ajuste este completa
				let pawnAdjusment = JSON.parse(pawn.content);
				let verify = false;
				for (let key in pawnAdjusment) {
					if (pawnAdjusment.hasOwnProperty(key)) {
						if (pawnAdjusment[key] === null || pawnAdjusment[key] === "") {
							verify = true; // El objeto tiene al menos un campo vacío o nulo
						}
					}
				}
				if (verify) {
					pawn.percentage = 90;
				} else {
					pawn.percentage = 100;
				}
			} else {
				pawn.percentage = 0;
			}
		});
		return result;
	}

	const columns = [
		{
			name: "id",
			label: "ID"
		},
		{
			name: "percentage",
			options: {
				customBodyRender: (value) => {
					return (
						<>
							<LinearProgress variant="determinate" color='primary' value={value} />
							<Typography variant="body2" color="textSecondary" align="center">
								{`${value}%`}
							</Typography>
						</>
					);
				}
			},
			label: "Completado"
		},
		{
			name: "vin",
			label: "VIN"
		},
		{
			name: "name",
			label: "Nombre"
		},
		{
			name: "last_editor",
			label: "Último en editar"
		},
		{
			name: "created_at",
			options: {
				customBodyRender: (value) => {
					return (
						<Moment locale="es-mx" format="D MMM YYYY" withTitle>
							{value}
						</Moment>
					);
				}
			},
			label: "Fecha de Alta"
		},
		{
			name: "Acciones",
			options: {
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<>
							<Grid container spacing={2}>
								<Grid item xs={2}>
									<Tooltip title="Ver">
										<IconButton aria-label="viewPawnAdjustmentSlip" size="small" onClick={() => handleRenderComponentChild(tableMeta, 'view')} sx={{ color: '#13b97a' }}>
											<VisibilityOutlinedIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
								<Grid item xs={2}>
									<Tooltip title="Cotización de Préstamo">
										<IconButton aria-label="quotation1PawnAdjustmentSlip" size="small" onClick={() => handleRenderComponentChild(tableMeta, 'quotation1')} sx={{ color: '#13b97a' }}>
											<ContentPasteOutlinedIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
								<Grid item xs={2}>
									<Tooltip title="Cotización de Préstamo (Seguro e IVA)">
										<IconButton aria-label="quotation1PawnAdjustmentSlip" size="small" onClick={() => handleRenderComponentChild(tableMeta, 'quotation2')} sx={{ color: '#13b97a' }}>
											<ContentPasteOutlinedIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
								<Grid item xs={2}>
									<Tooltip title="Editores">
										<IconButton aria-label="editorsPawnAdjustmentSlip" size="small" onClick={() => handleRenderComponentChild(tableMeta, 'editors')} sx={{ color: '#13b97a' }}>
											<ManageSearchOutlinedIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
								<Grid item xs={2}>
									<Tooltip title="Descargar">
										<IconButton aria-label="downloadPawnAdjustmentSlip" size="small" onClick={() => downloadPawnAdjustmentSlip(tableMeta)} sx={{ color: '#13b97a' }}>
											<CloudDownloadOutlinedIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</>
					);
				}
			}
		}
	];

	const options = {
		selectableRowsHideCheckboxes: true,
		filterType: 'textField',
		textLabels: {
			body: {
				noMatch: "Lo sentimos, no se encontraron registros coincidentes.",
				toolTip: "Sort",
				columnHeaderTooltip: column => `Sort for ${column.label}`
			},
			pagination: {
				next: "Siguiente pagina",
				previous: "Pagina anterior",
				rowsPerPage: "Filas por pagina:",
				displayRows: "de",
			},
			toolbar: {
				search: "Buscar",
				downloadCsv: "Descargar CSV",
				print: "Imprimir",
				viewColumns: "Ver columnas",
				filterTable: "Tabla de filtros",
			}
		}
	};

	const handleRenderComponentChild = (tableMeta, render) => {

		let id = tableMeta.rowData[0];
		let pawn = pawnAdjustmentSlips.find(pawn => pawn.id === id);

		if (render === 'view') {
			setData(pawn);
			setRenderComponentChild('view');

		} else if (render === 'editors') {
			let pawnAdjustment = pawnAdjustmentSlips.find(pawn => pawn.id === id);
			let id_pawn_adjustment_slip = pawnAdjustment.id_pawn_adjustment_slip;
			setIdPawnAdjustmentSlip(id_pawn_adjustment_slip);
			setRenderComponentChild('editors');

		} else if (render === 'quotation1') {
			setData(pawn);
			setRenderComponentChild('quotation1');

		} else if (render === 'quotation2') {
			setData(pawn);
			setRenderComponentChild('quotation2');
		}
		setOpenComponentChild(true);
	}

	const resetComponentChild = () => {
		setOpenComponentChild(false);
		setRenderComponentChild('');
	}

	const downloadPawnAdjustmentSlip = (tableMeta) => {
		let id = tableMeta.rowData[0];
		let { content } = pawnAdjustmentSlips.find(pawn => pawn.id === id);
		let info = JSON.parse(content);

		const doc = new jsPDF("p", "mm", "a4");

		// Definir el tamaño de la letra
		doc.setFontSize(13);

		// Datos del usuario
		// doc.addImage("https://spingo.mx/hs-fs/hubfs/logo-1-1.png?width=126&height=36&name=logo-1-1.png", "PNG", 10, 10, 30, 20);
		doc.text(`DATOS DEL PROSPECTO`, 10, 10);
		doc.setFontSize(10);
		doc.text(`FECHA: ${info.user_fecha}`, 10, 15);
		doc.text(`NOMBRE: ${info.user_nombre}`, 10, 20);
		doc.text(`EDAD: ${info.user_edad}`, 10, 25);
		doc.text(`CURP: ${info.user_curp}`, 10, 30);
		doc.text(`DIRECCIÓN: ${info.user_direccion}`, 10, 35);
		doc.text(`NACIONALIDAD: ${info.user_nacionalidad}`, 10, 40);
		doc.text(`EMAIL: ${info.user_email}`, 10, 45);
		doc.text(`TELÉFONO: ${info.user_telefono}`, 10, 50);

		doc.setFontSize(13);
		doc.text(`DATOS DEL VEHÍCULO`, 10, 65);
		doc.setFontSize(10);
		doc.text(`MARCA: ${info.car_marca}`, 10, 70);
		doc.text(`VIN: ${info.car_vin}`, 10, 75);
		doc.text(`MODELO: ${info.car_modelo}`, 10, 80);
		doc.text(`MOTOR: ${info.car_motor}`, 10, 85);
		doc.text(`TIPO: ${info.car_tipo}`, 10, 90);
		doc.text(`KILOMETRAJE: ${info.car_kilometraje}`, 10, 95);
		doc.text(`PLACA: ${info.car_placa}`, 10, 100);
		doc.text(`COLOR: ${info.car_color}`, 10, 105);
		doc.text(`TRANSMISIÓN: ${info.car_transmision}`, 10, 110);
		doc.text(`PLATAFORMA: ${info.car_plataforma}`, 10, 115);

		doc.setFontSize(13);
		doc.text(`DEDUCCIONES`, 10, 130);
		doc.setFontSize(10);
		doc.text(`KILOMETRAJE: ${info.deduccion_deduccion_kilometraje}%`, 10, 135);
		doc.text(`TIPO VEHÍCULO: ${info.deduccion_tipo_vehiculo}%`, 10, 140);
		doc.text(`MECÁNICA: $${info.deduccion_mecanica}`, 10, 145);
		doc.text(`CARROCERÍA: $${info.deduccion_carroceria}`, 10, 150);
		doc.text(`SUSPENSIÓN: $${info.deduccion_suspension}`, 10, 155);
		doc.text(`NEUMÁTICOS: $${info.deduccion_neumaticos}`, 10, 160);
		doc.text(`CRISTALES: $${info.deduccion_cristales}`, 10, 165);
		doc.text(`TENENCIAS: $${info.deduccion_tenencias}`, 10, 170);
		doc.text(`INFRACCIONES: $${info.deduccion_infracciones}`, 10, 175);
		doc.text(`C/PROPIETARIO: $${info.deduccion_propietario}`, 10, 180);
		doc.text(`V/EXTEMPORÁNEA: $${info.deduccion_extemporanea}`, 10, 185);
		doc.text(`TOTAL DE DESCUENTOS: $${info.deduccion_totalDescuentos}`, 10, 190);

		doc.setFontSize(13);
		doc.text(`AJUSTE PRESTAMO`, 10, 205);
		doc.setFontSize(10);
		doc.text(`TIPO VEHÍCULO: ${info.ajuste_tipo_vehiculo}`, 10, 210);
		doc.text(`PRECIO GUIA AUTO MÉTRICA: $${info.ajuste_precio_guia_autometrica}`, 10, 215);
		doc.text(`VALOR DE AVALUÓ: $${info.ajuste_valor_avaluo}`, 10, 220);
		doc.text(`AJUSTE DE AVALUÓ POR KM: $${info.ajuste_avaluo_km}`, 10, 225);
		doc.text(`AJUSTE DE AVALUÓ POR TIPO DE VEHÍCULO: $${info.ajuste_avaluo_tipo_vehiculo}`, 10, 230);
		doc.text(`VALOR AVALUÓ AJUSTADO: $${info.ajuste_avaluo_ajustado}`, 10, 235);
		doc.text(`D/MULTAS: $${info.ajuste_multas}`, 10, 240);
		doc.text(`D/POR TRAMITES: $${info.ajuste_tramites}`, 10, 245);
		doc.text(`DAÑOS ESTÉTICOS Y MECÁNICOS: $${info.ajuste_averia_estetica_mecanica}`, 10, 250);
		doc.text(`MONTO A PRESTAR: $${info.ajuste_monto_prestar}`, 10, 255);
		doc.text(`MARGEN A FAVOR DEL CLIENTE: $${info.ajuste_margen_favor}`, 10, 260);
		doc.text(`MONTO SOLICITADO POR EL CLIENTE: $${info.ajuste_monto_solicitado_cliente}`, 10, 265);

		// Guardar PDF
		doc.save(`Boleta_Ajuste_${new Date().getTime()}.pdf`);
	}

	const updateDataTable = () => {
		setIsLoading(true);
		getPawnAdjustmentSlips().then(result => {
			const newResult = checkProgressPercentage(result.data);
			setPawnAdjustmentSlips(newResult);
			setIsLoading(false);
		});
	}

	return (
		<Dialog
			fullScreen
			open={open}
			onClose={onClose}
			TransitionComponent={Transition}
		>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<AppBar sx={{ position: 'relative', bgcolor: '#3340e3' }}>
				<Toolbar>
					<IconButton
						edge="start"
						color="inherit"
						onClick={onClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
					<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
						Boletas de Ajuste de Empeño
					</Typography>
					<Tooltip title="Actualizar contenido">
						<IconButton sx={{ color: 'white' }} aria-label="refresh-content" size="medium" onClick={updateDataTable}>
							<RefreshIcon fontSize="inherit" />
						</IconButton>
					</Tooltip>
				</Toolbar>
			</AppBar>
			<MUIDataTable
				title={"Boletas de Ajuste de Empeño"}
				data={pawnAdjustmentSlips}
				columns={columns}
				options={options}
			/>

			{/* RENDERIZAR COMPONENTES HIJOS */}
			{(renderComponentChild === 'view') && <View open={openComponentChild} onClose={resetComponentChild} data={data} />}
			{(renderComponentChild === 'quotation1') && <Quotation1 open={openComponentChild} onClose={resetComponentChild} pawnAdjustmentSlip={data} />}
			{(renderComponentChild === 'quotation2') && <Quotation2 open={openComponentChild} onClose={resetComponentChild} pawnAdjustmentSlip={data} />}
			{(renderComponentChild === 'editors') && <Editors open={openComponentChild} onClose={resetComponentChild} idPawnAdjustmentSlip={idPawnAdjustmentSlip} />}
		</Dialog>
	);
};

export default ListPawnAdjustmentSlips;