import React, { useState } from 'react';

// * IMPORT HOOKS CUSTOMS
import { useAlert } from '../../hooks/useAlert';
import { useForm } from '../../hooks/useForm';

// * IMPORTS HELPERS
import { Global } from '../../helpers/Global';

// * IMPORT MATERIAL UI - ALERT TOAST
import Snackbar from '@mui/material/Snackbar';

// * IMPORT MATERIAL UI - CONTAINERS
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// * IMPORTS MATERIAL UL - PROGRESS SPINNER
import CircularProgress from '@mui/material/CircularProgress';


import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


export const ValidateCfdi = () => {

    // * HOOKS
    // Data CFDI
    const [cfdi, setCfdi] = useState({});
    // Data form
    const { form, changed } = useForm({});
    // Loading
    const [loading, setLoading] = useState(false);
    // Alerts Message
    const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();

    const validateCfdi = async (event) => {

        event.preventDefault();

        if (!form.folio || !form.rfc_emisor || !form.rfc_receptor) {
            showStatusMessage('Todos los campos son obligatorios...', 'warning');
            return false;
        }

        setLoading(true);

        let raw = JSON.stringify(form);

        // Preparar peticion a API
        let requestOptions = {
            method: 'POST',
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            body: raw
        };

        let request = await fetch(`${Global.url_api}api/validate-cfdi`, requestOptions);
        let response = await request.json();

        let result = JSON.parse(response);

        if (result.estatus === 'OK') {
            showStatusMessage('¡CFDI validado correctamente!', 'success');
            setCfdi(result);
        }
        else if (result.estatus === 'ERROR') {
            showStatusMessage(result.mensaje, 'warning');
        }
        else {
            showStatusMessage('Error al procesar solicitud', 'error');
        }
        setLoading(false);
    }

    return (
        <div>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
                <Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>

            <Container maxWidth="md">

                <h4>Validar CFDI</h4>
                <Box component="form" onSubmit={validateCfdi} sx={{ bgcolor: 'white', '& .MuiTextField-root': { m: 1, width: '25ch' } }} noValidate autoComplete="off" >
                    <FormControl>
                        <div>
                            <TextField onChange={changed} id="folio" name="folio" label="Folio" variant="standard" />
                            <TextField onChange={changed} id="rfc_emisor" name="rfc_emisor" label="RFC Emisor" variant="standard" />
                            <TextField onChange={changed} id="rfc_receptor" name="rfc_receptor" label="RFC Receptor" variant="standard" />
                        </div>
                        <Button type="submit" size="large" variant="contained" sx={{ mt: 3, mb: 2 }} >
                            Validar
                        </Button>
                    </FormControl>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        {/* Spinner Loader para procesar peticiones */}
                        {(loading === true) && <CircularProgress />}
                    </Box>

                    {/* Si se valida correctamente el CFDI */}
                    {
                        (Object.keys(cfdi).length !== 0) &&
                        <>
                            <Typography variant="subtitle1" gutterBottom>
                                Efecto comprobante: {cfdi.efectoComprobante}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Estado CFDI: {cfdi.estadoCFDI}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Estatus cancelacion: {cfdi.estatusCancelacion}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Fecha de certificación: {cfdi.fechaCertificacion}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Fecha de emisión: {cfdi.fechaEmision}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Folio fiscal: {cfdi.folioFiscal}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Nombre emisor: {cfdi.nombreEmisor}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Nombre receptor: {cfdi.nombreReceptor}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                RFC Emisor: {cfdi.rfcEmisor}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                RFC Pac: {cfdi.rfcPac}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                RFC receptor: {cfdi.rfcReceptor}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Total CFDI: {cfdi.totalCfdi}
                            </Typography>
                        </>
                    }
                </Box>
            </Container>

        </div>
    )
}
