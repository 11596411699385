import React from 'react';

import { Identification } from './onboarding/Identification';
import { ProofAddress } from './onboarding/ProofAddress';
import { CirculationCard } from './onboarding/CirculationCard';
import { Bill } from './onboarding/Bill';

export const UploadOnlyDocument = ({ selectDocument, idUser }) => {

	switch (selectDocument) {
		case 'identification':

			return (
				<Identification idUser={idUser} />
			)

		case 'proof-address':

			return (
				<ProofAddress idUser={idUser} />
			)

		case 'circulation-card':
			return (
				<CirculationCard idUser={idUser} />
			)

		case 'bill':

			return (
				<Bill idUser={idUser} />
			)

		default:
			break;
	}
}
