export const Global = {
    client_id_google: "534112475501-qrn3hav796olnstqrqrtnp44lb3867ff.apps.googleusercontent.com",
    
    // * HOST LOCAL DEV BACKEND
    // url_api : "http://127.0.0.1:8000/",
    // url_api_root : "http://127.0.0.1:8000/",

    // * HOST PRODUCTION BACKEND
    url_api: "https://api-rest.spingo.mx/public/",
    url_api_root: "https://api-rest.spingo.mx/",

    // * HOST FRONTEND
    // url_app: 'http://localhost:3000/',
    url_app : 'https://solicitudcredito.spingo.mx/',
};