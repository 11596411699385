import React, { useEffect, useState } from 'react';

import { Global } from '../../../../helpers/Global';
import { useAlert } from '../../../../hooks/useAlert';
import { addMonthsToDate } from '../../../utils/Time';

import { Styles } from '../../../../helpers/Styles';

import logoSpingo from '../../../../assets/img/general/logo-hd.png';

import html2canvas from 'html2canvas';

import Moment from 'moment';
import 'moment/locale/es';

import { Container, Grid, Dialog, AppBar, Toolbar, IconButton, Typography, Slide, Card, CardContent, TextField, Button, InputAdornment, List, ListItem, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Paper, MenuItem, Snackbar } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';

import { styled } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const Quotation2 = ({ open, onClose, pawnAdjustmentSlip }) => {

	const [idPawnAdjustmentSlip, setIdPawnAdjustmentSlip] = useState();

	const getLoanQuoteInsuranceTax = async (idPawnAdjustmentSlip) => {
		// Conseguir el token de usuario
		const token = localStorage.getItem("token");

		let request = await fetch(`${Global.url_api}api/auth/get-loan-quote-insurance-tax`, {
			method: 'POST',
			headers: {
				"Authorization": `Bearer  ${token}`,
				"Content-Type": "application/json"
			},
			redirect: 'follow',
			body: JSON.stringify({ id_pawn_adjustment_slip: idPawnAdjustmentSlip })
		});

		let response = await request.json();
		return response;
	}

	useEffect(() => {
		let { content } = pawnAdjustmentSlip;
		let dataPrev = JSON.parse(content);

		// Verificar si el cliente pide menos dinero del monto a prestar
		let montoPrestarTemp = verifyLoanAmount(dataPrev);

		let temp = {
			date_start: '',
			date_end: '',
			user_nombre: `${dataPrev.user_nombre} ${dataPrev.user_apellido_paterno !== undefined && dataPrev.user_apellido_paterno !== null ? dataPrev.user_apellido_paterno : ''} ${dataPrev.user_apellido_materno !== undefined && dataPrev.user_apellido_materno !== null ? dataPrev.user_apellido_materno : ''}`,
			car_descripcion: dataPrev.car_tipo,
			car_modelo: dataPrev.car_modelo,

			prestamoTotal: montoPrestarTemp,
			plazoMeses: 0,
			tasaInteres: 4.9,
			interesMensual: 0,
			seguro: 0,
			gps: 750,
			iva: 0,
			importe: 0
		}
		setFormData(temp);
		setIdPawnAdjustmentSlip(parseInt(dataPrev.id_pawn_adjustment_slip));

		// Si previamente ya se guardo una cotizacion de prestamo de seguro + iva
		const fetchData = async () => {
			const { code, data } = await getLoanQuoteInsuranceTax(parseInt(dataPrev.id_pawn_adjustment_slip));

			if (code === 201 && data !== null) {
				setFormData({
					date_start: data.date_start,
					date_end: data.date_end,
					user_nombre: data.name,
					car_descripcion: data.car_descripcion,
					car_modelo: data.car_modelo,

					prestamoTotal: data.prestamo_total,
					plazoMeses: data.plazo_meses,
					tasaInteres: data.tasa_interes,
					interesMensual: data.interes_mensual,
					seguro: data.seguro,
					gps: data.gps,
					iva: data.iva,
					importe: data.importe
				});
			}
		};

		fetchData();

	}, [pawnAdjustmentSlip]);

	const [formData, setFormData] = useState({
		date_start: '',
		date_end: '',
		user_nombre: '',
		car_descripcion: '',
		car_modelo: '',

		prestamoTotal: 0,
		plazoMeses: 1,
		tasaInteres: 0,
		interesMensual: 0,
		seguro: 0,
		gps: 750,
		iva: 0,
		importe: 0
	});

	const [enabledButton, setEnabledButton] = useState(true);

	// Alerts Message
	const { showStatusMessage, openSnackbar, messageSnackbar, severitySnackbar, closeSnackbar, Alert } = useAlert();

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Desactivar boton de "Guardar"
		setEnabledButton(false);

		formData.id_pawn_adjustment_slip = idPawnAdjustmentSlip
		console.log(formData);

		// Conseguir el token de usuario
		const token = localStorage.getItem("token");

		try {

			let request = await fetch(`${Global.url_api}api/auth/loan-quote-insurance-tax`, {
				method: 'POST',
				headers: {
					"Authorization": `Bearer  ${token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(formData),
				redirect: 'follow'
			});

			let { status, message, code } = await request.json();

			if (code === 201 && status === "success") {
				showStatusMessage('¡Cotización de Préstamo (seguro e iva ) guardada correctamente!', 'success');
			} else {
				showStatusMessage(`Error inesperado`, 'warning');
				console.log(JSON.stringify(message));
			}

		} catch (error) {
			console.log(error);
			showStatusMessage(`error client: ${error}`, 'error');
		}

		// Activar boton de "Guardar"
		setEnabledButton(true);
	};

	const resetLoanQuoteInitial = () => {
		let { content } = pawnAdjustmentSlip;
		let dataReset = JSON.parse(content);
		setFormData(prevFormData => ({
			...prevFormData,
			prestamoTotal: verifyLoanAmount(dataReset),
		}));
	}

	const verifyLoanAmount = (dataPawnAdjustmentSlip) => {
		let montoPrestarTemp;
		if (parseInt(dataPawnAdjustmentSlip.ajuste_monto_solicitado_cliente) >= 25000 && parseInt(dataPawnAdjustmentSlip.ajuste_monto_solicitado_cliente) <= parseInt(dataPawnAdjustmentSlip.ajuste_monto_prestar)) {
			console.log('Se asigno el monto del cliente');
			montoPrestarTemp = dataPawnAdjustmentSlip.ajuste_monto_solicitado_cliente;
		} else {
			console.log('Se asigno el monto a prestar');
			montoPrestarTemp = dataPawnAdjustmentSlip.ajuste_monto_prestar;
		}
		return montoPrestarTemp
	}

	const calculateLoanEstimate = (e) => {
		const { prestamoTotal, tasaInteres, gps, seguro } = formData;

		const nuevoInteresMensual = (parseInt(prestamoTotal) * (parseFloat(tasaInteres) / 100)).toFixed(2);
		const nuevoIVA = ((parseFloat(nuevoInteresMensual) + parseInt(gps) + parseInt(seguro)) * 0.16).toFixed(2);
		const nuevoImporte = (parseInt(gps) + parseInt(seguro) + parseFloat(nuevoInteresMensual) + parseFloat(nuevoIVA)).toFixed(2);

		setFormData(prevData => ({
			...prevData,
			interesMensual: nuevoInteresMensual,
			importe: nuevoImporte,
			iva: nuevoIVA
		}));
	}

	const downloadImage = () => {
		const tabla = document.getElementById('Quotation2'); // Reemplaza 'Quotation2' con el id de tu tabla
		if (tabla) {
			html2canvas(tabla).then((canvas) => {
				const imgData = canvas.toDataURL('image/png');
				const link = document.createElement('a');
				link.href = imgData;
				link.download = `${new Date().getTime()}.png`;
				link.click();
			});
		}
	};

	const CustomButton = styled(Button)(() => ({
		color: 'white',
		backgroundColor: Styles.color_two,
		'&:hover': {
			backgroundColor: Styles.color_two_hover,
		},
	}));

	return (
		<Dialog
			fullScreen
			open={open}
			onClose={onClose}
			TransitionComponent={Transition}
		>
			<AppBar sx={{ position: 'relative', bgcolor: Styles.color_one }}>
				<Toolbar>
					<IconButton
						edge="start"
						color="inherit"
						onClick={onClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
					<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
						Cotización de Préstamo (Seguro e IVA)
					</Typography>
				</Toolbar>
			</AppBar>

			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
				<Alert sx={{ height: 'auto', width: '350px', paddingTop: 'auto' }} onClose={closeSnackbar} severity={severitySnackbar}>
					{messageSnackbar}
				</Alert>
			</Snackbar>

			<Container maxWidth="xl" sx={{ bgcolor: '#e7ebf0' }}> {/* Puedes ajustar el valor de `maxWidth` según tus necesidades */}
				<Card sx={{ marginTop: '2rem' }}>
					<CardContent>
						<form onSubmit={handleSubmit}>
							<Grid container spacing={2} sx={{ marginTop: '1rem' }} justify="center" alignItems="center">
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										size='small'
										label="Fecha de Cotización"
										type="date"
										name="date_start"
										value={formData.date_start}
										onChange={handleInputChange}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										size='small'
										label="Cotización Válida Hasta"
										type="date"
										name="date_end"
										value={formData.date_end}
										onChange={handleInputChange}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										size='small'
										label="Nombre del Cliente"
										name="user_nombre"
										value={formData.user_nombre}
										onChange={handleInputChange}
										InputProps={{
											readOnly: true,
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										size='small'
										label="Descripción del Vehículo"
										name="car_descripcion"
										value={formData.car_descripcion}
										onChange={handleInputChange}
										InputProps={{
											readOnly: true,
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										size='small'
										label="Año del Vehículo"
										name="car_modelo"
										value={formData.car_modelo}
										onChange={handleInputChange}
										InputProps={{
											// readOnly: true,
										}}
									/>
								</Grid>

								<Grid item xs={12} sm={4}>
									<TextField
										fullWidth
										size="small"
										label="Préstamo Total"
										type="number"
										name="prestamoTotal"
										value={formData.prestamoTotal}
										onChange={handleInputChange}
										InputProps={{
											startAdornment: <InputAdornment position="start">$</InputAdornment>,
											readOnly: true,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										fullWidth
										size="small"
										label="Plazo en Meses"
										type="number"
										name="plazoMeses"
										value={formData.plazoMeses}
										onChange={handleInputChange}
										InputProps={{
											startAdornment: <InputAdornment position="start">#</InputAdornment>,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										fullWidth
										size="small"
										label="Tasa de Interés"
										type="number"
										name="tasaInteres"
										value={formData.tasaInteres}
										onChange={handleInputChange}
										InputProps={{
											startAdornment: <InputAdornment position="start">%</InputAdornment>,
											readOnly: true,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										fullWidth
										size="small"
										label="Interés Mensual"
										type="text"
										name="interesMensual"
										value={formData.interesMensual}
										onChange={handleInputChange}
										InputProps={{
											startAdornment: <InputAdornment position="start">$</InputAdornment>,
											readOnly: true,
											inputMode: 'numeric',
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										fullWidth
										size="small"
										label="Seguro"
										type="number"
										name="seguro"
										value={formData.seguro}
										onChange={handleInputChange}
										InputProps={{
											startAdornment: <InputAdornment position="start">$</InputAdornment>,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										select
										fullWidth
										size="small"
										label="GPS"
										type="number"
										name="gps"
										value={formData.gps}
										onChange={handleInputChange}
										InputProps={{
											startAdornment: <InputAdornment position="start">$</InputAdornment>
										}}
									>
										<MenuItem value="750">750</MenuItem>
										<MenuItem value="800">800</MenuItem>
										<MenuItem value="850">850</MenuItem>
									</TextField>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										fullWidth
										size="small"
										label="IVA"
										type="number"
										name="iva"
										value={formData.iva}
										onChange={handleInputChange}
										InputProps={{
											startAdornment: <InputAdornment position="start">$</InputAdornment>,
											readOnly: true,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										fullWidth
										size="small"
										label="Importe"
										type="number"
										name="importe"
										value={formData.importe}
										onChange={handleInputChange}
										InputProps={{
											startAdornment: <InputAdornment position="start">$</InputAdornment>,
											readOnly: true,
										}}
									/>
								</Grid>
								<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
									<Grid container>
										<Grid item xs={12} md={3} mt={2}>
											<CustomButton startIcon={<RequestPageOutlinedIcon />} variant="contained" color="primary" onClick={calculateLoanEstimate}>
												Calcular
											</CustomButton>
										</Grid>
										<Grid item xs={12} md={3} mt={2}>
											<CustomButton startIcon={<DownloadForOfflineOutlinedIcon />} variant="contained" color="primary" onClick={downloadImage}>
												Descargar
											</CustomButton>
										</Grid>
										<Grid item xs={12} md={3} mt={2}>
											<CustomButton startIcon={<RestartAltOutlinedIcon />} variant="contained" color="primary" onClick={resetLoanQuoteInitial}>
												Reiniciar Préstamo Total
											</CustomButton>
										</Grid>
										<Grid item xs={12} md={3} mt={2}>
											<CustomButton startIcon={<SaveOutlinedIcon />} disabled={!enabledButton} variant="contained" color="primary" type="submit">
												{enabledButton ? 'Guardar' : 'Guardando...'}
											</CustomButton>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>

				<Card id="Quotation2" sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
					<CardContent>
						<Grid container alignItems="center" justifyContent="space-between">
							<Grid item xs={9}>
								<Typography variant="h5" align="center" sx={{ color: '#3240e4', fontWeight: 'bold' }}>
									COTIZACIÓN DE PRÉSTAMO
								</Typography>
							</Grid>
							<Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<img
									src={logoSpingo} // Reemplaza esta ruta con la ruta real de tu imagen/logo
									alt="Logo SpinGo"
									style={{ width: '50%', height: 'auto' }}
								/>
							</Grid>
						</Grid>

						<TableContainer component={Paper} sx={{ marginTop: '1rem' }}>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell sx={{ width: '50%' }}><Typography variant="h5" gutterBottom>FECHA DE COTIZACIÓN: <strong>{formData.date_start !== '' ? Moment(formData.date_start).locale('es').format('DD [de] MMMM [de] YYYY') : ''}</strong></Typography>

										</TableCell>
										<TableCell sx={{ width: '50%' }}><Typography variant="h5" gutterBottom>COTIZACIÓN VÁLIDA HASTA: <strong>{formData.date_end !== '' ? Moment(formData.date_end).locale('es').format('DD [de] MMMM [de] YYYY') : ''}</strong></Typography>

										</TableCell>
									</TableRow>

								</TableBody>
							</Table>
						</TableContainer>

						<TableContainer component={Paper}>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell sx={{ width: '20%' }}><Typography variant="h5" gutterBottom>Nombre del cliente:</Typography></TableCell>
										<TableCell sx={{ bgcolor: Styles.color_two }}><Typography variant="h5" gutterBottom>{formData.user_nombre}</Typography></TableCell>
									</TableRow>
									<TableRow>
										<TableCell><Typography variant="h5" gutterBottom>Descripción del Vehículo:</Typography></TableCell>
										<TableCell sx={{ bgcolor: Styles.color_two }}><Typography variant="h5" gutterBottom>{formData.car_descripcion}</Typography></TableCell>
									</TableRow>
									<TableRow>
										<TableCell><Typography variant="h5" gutterBottom>Año del Vehículo:</Typography></TableCell>
										<TableCell sx={{ bgcolor: Styles.color_two }}><Typography variant="h5" gutterBottom>{formData.car_modelo}</Typography></TableCell>
									</TableRow>
									<TableRow>
										<TableCell><Typography variant="h5" mt={7} gutterBottom>Préstamo Total:</Typography></TableCell>
										{/* 
										<Table>
											<TableHead sx={{ bgcolor: Styles.color_one }}>
												<TableRow>
													<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Monto del préstamo**</Typography></TableCell>
													<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Plazo meses*</Typography></TableCell>
													<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Tasa</Typography></TableCell>
													<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Interes mensual</Typography></TableCell>
													<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Seguro y GPS</Typography></TableCell>
													<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>IVA</Typography></TableCell>
													<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Importe</Typography></TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow>
													<TableCell sx={{ color: Styles.color_one, textAlign: 'center' }}><Typography variant="h5" gutterBottom>{parseFloat(formData.prestamoTotal).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography></TableCell>
													<TableCell sx={{ textAlign: 'center' }}><Typography variant="h5" gutterBottom>{formData.plazoMeses}</Typography></TableCell>
													<TableCell sx={{ textAlign: 'center' }}><Typography variant="h5" gutterBottom>{formData.tasaInteres}%</Typography></TableCell>
													<TableCell sx={{ textAlign: 'center' }}><Typography variant="h5" gutterBottom>{parseFloat(formData.interesMensual).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography></TableCell>
													<TableCell sx={{ textAlign: 'center' }}><Typography variant="h5" gutterBottom>{(parseFloat(formData.gps) + parseFloat(formData.seguro)).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography></TableCell>
													<TableCell sx={{ textAlign: 'center' }}><Typography variant="h5" gutterBottom>{parseFloat(formData.iva).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography></TableCell>
													<TableCell sx={{ textAlign: 'center' }}><Typography variant="h5" gutterBottom>{parseFloat(formData.importe).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography></TableCell>
												</TableRow>
											</TableBody>
										</Table> 
										*/}
										{formData.plazoMeses >= "1" && (
											<Table>
												<TableHead sx={{ bgcolor: Styles.color_one }}>
													<TableRow>
														<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Plazo meses*</Typography></TableCell>
														<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Monto del préstamo</Typography></TableCell>
														<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Tasa</Typography></TableCell>
														<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Interes mensual</Typography></TableCell>
														<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Seguro y GPS</Typography></TableCell>
														<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>IVA</Typography></TableCell>
														<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Por Refrendo</Typography></TableCell>
														<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Por Desempeño</Typography></TableCell>
														<TableCell style={{ color: 'white', fontWeight: 'bold' }}><Typography variant="h5" gutterBottom>Fecha de Pago</Typography></TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{[...Array(parseInt(formData.plazoMeses))].map((_, index) => (
														<TableRow key={index}>
															<TableCell sx={{ textAlign: 'center' }}>
																<Typography variant="h5" gutterBottom>{index + 1}</Typography>
															</TableCell>
															<TableCell sx={{ color: Styles.color_one, textAlign: 'center' }}>
																<Typography variant="h5" gutterBottom>
																	{parseFloat(formData.prestamoTotal).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
																</Typography>
															</TableCell>
															<TableCell sx={{ textAlign: 'center' }}>
																<Typography variant="h5" gutterBottom>{formData.tasaInteres}%</Typography>
															</TableCell>
															<TableCell sx={{ textAlign: 'center' }}>
																<Typography variant="h5" gutterBottom>
																	{parseFloat(formData.interesMensual).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
																</Typography>
															</TableCell>
															<TableCell sx={{ textAlign: 'center' }}>
																<Typography variant="h5" gutterBottom>
																	{(parseFloat(formData.gps) + parseFloat(formData.seguro)).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
																</Typography>
															</TableCell>
															<TableCell sx={{ textAlign: 'center' }}>
																<Typography variant="h5" gutterBottom>
																	{parseFloat(formData.iva).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
																</Typography>
															</TableCell>
															<TableCell sx={{ textAlign: 'center' }}>
																<Typography variant="h5" gutterBottom>
																	{parseFloat(formData.importe).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
																</Typography>
															</TableCell>
															<TableCell sx={{ textAlign: 'center' }}>
																<Typography variant="h5" gutterBottom>
																	{parseInt(formData.plazoMeses) === index + 1 ?
																		<>{parseFloat(parseFloat(formData.importe) + parseFloat(formData.prestamoTotal)).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</> :
																		<>$0</>
																	}
																</Typography>
															</TableCell>
															<TableCell sx={{ textAlign: 'center' }}>
																<Typography variant="h5" gutterBottom>
																	{addMonthsToDate(formData.date_start, index + 1)}
																</Typography>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										)}
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>

						<Grid container alignItems="center" justifyContent="space-between" mt={5}>
							<Grid item xs={8}>
								<List>
									<ListItem>
										<Typography variant="h5" gutterBottom>*Pagos a capital en cualquier momento aplicados al siguiente mes y no al término del contrato.</Typography>
									</ListItem>
									<ListItem>
										<Typography variant="h5" gutterBottom>**Cotización sujeta por ajustes por la revisión física y mecánica de la unidad.</Typography>
									</ListItem>
									<ListItem>
										<Typography variant="h5" gutterBottom>***Refrendos ilimitados, actualizando cada 4 meses de acuerdo al valor de su vehículo del importe del préstamo.</Typography>
									</ListItem>
								</List>
							</Grid>
							<Grid item xs={4} mt={5} sx={{ textAlign: 'center' }}>
								<Typography variant="h5" display="block" gutterBottom>
									NOMBRE Y FIRMA
								</Typography>
								<Typography variant="h6" display="block" gutterBottom>
									Bajo su estricta responsabilidad y  por su propio derecho
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Container>
		</Dialog>
	)
}
