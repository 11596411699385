export const getDateNow = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const addDaysDate = (date, days) => {
  const dateObj = new Date(date);
  dateObj.setDate(dateObj.getDate() + days);
  return dateObj.toISOString().split('T')[0]; // Devuelve la nueva fecha en formato 'YYYY-MM-DD'
};

export const addOneMonthToDate = (dateString) => {
  // Parsear la fecha
  const date = new Date(dateString);

  // Sumar un mes
  date.setMonth(date.getMonth() + 1);

  // Obtener el día, mes y año
  const day = date.getDate();
  const month = date.getMonth() + 1; // Sumamos 1 porque los meses van de 0 a 11
  const year = date.getFullYear();

  return `${year}-0${month}-${day}`;
}

export const addMonthsToDate = (date, months) => {
  if (date === null) {
    console.log("Date null");
    return false;
  }
  // Asegúrate de que 'date' sea un objeto Date
  const parsedDate = typeof date === 'string' ? new Date(date) : date;

  // Verificar si el resultado es una fecha válida
  if (isNaN(parsedDate)) {
    console.log("Fecha inválida proporcionada");
    return false;
  }

  const newDate = new Date(parsedDate);
  newDate.setMonth(newDate.getMonth() + months);

  // Ajustar el día si el nuevo mes tiene menos días que el mes original
  // Utiliza el último día del mes original para evitar el problema de días faltantes
  const day = parsedDate.getDate();
  newDate.setDate(day);

  // Ajustar el día si es fuera del rango del nuevo mes
  if (newDate.getDate() !== day) {
    newDate.setDate(0); // Establece el día al último día del mes anterior
  }

  // Formatear la fecha como "DD/MM/YYYY"
  const formattedDay = String(newDate.getDate()).padStart(2, '0');
  const formattedMonth = String(newDate.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript van de 0 a 11
  const year = newDate.getFullYear();

  return `${formattedDay}/${formattedMonth}/${year}`;
}